<template>
  <div>
    <div class="header-wrapper-exception xforce-banner">
      <h2>Xforce</h2>
      <hr class="line" />
    </div>

    <b-container>
      <div class="grid">
        <div class="text">
          <p>
            XFORCE konzentriert sich stark auf Forschung und Entwicklung, was
            sicherstellt, dass ihre vielfältigen Auspuffprodukte von hoher
            technischer Präzision, stilvoll und praktisch gestaltet – um die
            Leistung zu maximieren – und vor allem technisch innovativ sind, wie
            unsere patentierte VAREX™-Schalldämpfung, die an sich eine
            Auspuffrevolution darstellt.
          </p>
          <p>
            Mit dem Fokus auf Innovation und umfassende Qualitätskontrolle kann
            XFORCE, Ihnen hervorragende Leistungswerte für Ihren Auspuff bieten.
            XFORCE ist stark in kontinuierliche Forschung und Entwicklung
            involviert, dazu kommt noch ihr Vorsprung in der Auspuffproduktion
            und im Automobilbau – das ist eine kraftvolle Verbindung. Ihre
            Auspuffsysteme werden speziell entwickelt und auf unserem
            hochmodernen Dynapack Evolution 3000 Rollenprüfstand getestet, um
            die maximale Leistung im Xforce Forschungs- und Entwicklungszentrum
            in Australien zu gewährleisten.
          </p>
        </div>
        <div class="image">
          <picture>
            <source
              type="image/webp"
              srcset="./../../assets/brands-logos/p10_xforce.webp"
            />
            <img
              src="./../../assets/brands-logos/p10_xforce.png"
              alt="Xforce"
            />
          </picture>
        </div>
      </div>
    </b-container>

    <page-header
      :text="$t('importantLinks')"
      font="black"
      back="white"
    ></page-header>

    <b-container>
      <div class="link-items">
        <router-link to="/catalogue/xforce">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Online-Katalog
          </div>
        </router-link>
        <!-- <a
            href="https://drive.google.com/open?id=1yZ0t1JXD-EuWlG5MNIYHvqKDDIYrOo4I"
            target="_blank"
          >
            <div class="link-item">
              <v-icon left class="icon">mdi-play</v-icon>Catalogue PDF
            </div>
          </a> -->
        <a href="http://xforce.eu/" target="_blank">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Webseite
          </div>
        </a>
        <a
          :href="
            $source === 'ozpartseu'
              ? 'https://drive.google.com/drive/folders/1ADhJnEp_xscrVlb3BH86ad3axo8B2NsX'
              : 'https://drive.google.com/open?id=1bPT7svkaHpD4pYJGf7aB3PE1jK9yysBs'
          "
          target="_blank"
          v-if="isUserLogged"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Useful Files
            <ul class="link-items-list">
              <li>Price Lists / Guides / Catalogues</li>
              <li>Artwork (Logos / Posters / Banners / Brochures )</li>
              <li>Product images</li>
              <li>Videos</li>
              <li>Technical Support / Fitting Instructions</li>
            </ul>
          </div>
        </a>
        <a href="https://www.youtube.com/user/xforceexhaust" target="_blank">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Youtube Channel
          </div>
        </a>
      </div>
      <div class="youtube-wrapper">
        <div class="youtube">
          <iframe
            width="100%"
            height="360"
            src="https://www.youtube.com/embed/xcfTcwAjNLU"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div>
          <p>XForce Engine-Back Auspuffanlage für Ford Mustang GT 5.0L 2015</p>
          <p>
            Hier ist unser eigener 2015er Mustang GT, ausgestattet mit dem neuen
            XForce 1-7/8"-Langrohrkrümmer und 3"-Edelstahl-Catback mit
            verstellbaren Varex-Endschalldämpfern. Hören Sie mal rein!
          </p>
          <p>
            Jetzt erhältlich bei Ihrem autorisierten XForce-Händler vor Ort:
            http://www.xforce.com.au/where-to-buy
          </p>
        </div>
      </div>
      <brands class="brands" />
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Brands from "@/components/brands/Brands.vue";
import PageHeader from "@/components/common/PageHeader.vue";

export default {
  name: "XforceEn",
  components: { Brands, PageHeader },
  computed: {
    ...mapGetters(["isUserLogged"])
  }
};
</script>
