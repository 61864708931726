<template>
  <div class="basket-kit-component my-2">
    <div class="photo pa-2 d-flex justify-center align-center">
      <img
        :src="
          kitComponent.item.photos[0].url || '../../assets/photo-holder.png'
        "
        :alt="kitComponent.displayname"
      />
    </div>
    <div>
      <router-link
        v-if="kitComponent.item.urlcomponent"
        class="my-link"
        :to="`/${kitComponent.item.urlcomponent}`"
        target="_blank"
        >{{ kitComponent.displayname }}</router-link
      >
      <span v-else>{{ kitComponent.displayname }}</span>
      <div>Total Qty.: {{ kitComponent.quantity }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "KitComponent",
  props: {
    kitComponent: Object
  }
};
</script>

<style lang="scss" scoped>
.my-link:hover {
  text-decoration: none;
}

.basket-kit-component {
  display: grid;
  grid-template-columns: 100px 1fr;
  column-gap: 20px;
}

.photo {
  border: 1px solid #ced4da;
  background-color: white;

  img {
    width: 100%;
    object-fit: contain;
  }
}
</style>
