<template>
  <div class="banner-wrapper">
    <marquee-component :marquee="marquee" />
    <b-container>
      <div class="swiper">
        <div class="swiper-wrapper">
          <div v-for="slide in slides" :key="slide.id" class="swiper-slide">
            <a v-if="slide.url" :href="slide.url">
              <img class="swiper-lazy" :data-src="slide.full_image_url"
            /></a>
            <img v-else class="swiper-lazy" :data-src="slide.full_image_url" />
            <div
              class="swiper-lazy-preloader swiper-lazy-preloader-black"
            ></div>
          </div>
        </div>
        <div class="swiper-pagination"></div>
        <div class="control control-next">
          <v-icon large class="nav-icon slide-next">mdi-chevron-right</v-icon>
        </div>
        <div class="control control-prev">
          <v-icon large class="nav-icon slide-prev">mdi-chevron-left</v-icon>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import MarqueeComponent from "./Marquee.vue";
import Swiper, { Navigation, Autoplay, Pagination, Lazy } from "swiper";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/lazy";

const url = "https://api.ozparts.eu/slider/ozperformance";

export default {
  name: "BannerComponent",
  components: { MarqueeComponent },
  props: {
    marquee: Object
  },
  data() {
    return {
      swiper: null,
      slides: []
    };
  },
  async mounted() {
    this.slides = (await fetch(url).then(res => res.json())) || [];
    this.swiper = new Swiper(".swiper", {
      preloadImages: false,
      lazy: true,
      autoplay: {
        delay: 5000
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        bulletActiveClass: "p-bullet"
      },
      navigation: {
        nextEl: ".slide-next",
        prevEl: ".slide-prev"
      },
      modules: [Navigation, Autoplay, Lazy, Pagination]
    });
  }
};
</script>

<style lang="scss" scoped>
.banner-wrapper {
  font-family: "Michroma", sans-serif;

  .swiper {
    position: relative;

    .control {
      position: absolute;
      top: 0;
      bottom: 0;
      z-index: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &-next {
        right: 10px;
      }

      &-prev {
        left: 10px;
      }
    }
  }

  img {
    height: 300px;
    width: 100%;
    object-fit: contain;
    @media screen and (max-width: 600px) {
      height: 170px;
      width: 100%;
      object-fit: contain;
    }
  }
}
</style>

<style>
.p-bullet {
  background-color: red;
  opacity: 1;
}
</style>
