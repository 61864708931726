import axios from "axios";
import cfg from "./3c.config";

let currencyInterceptor = null;

const axios3C = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL
});

axios3C.interceptors.request.use(
  function(config) {
    if (config.data) {
      config.data.source = cfg.SOURCE;
    }
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

export const configureCurrency = currency => {
  if (currencyInterceptor) {
    axios3C.interceptors.request.eject(currencyInterceptor);
  }
  currencyInterceptor = axios3C.interceptors.request.use(
    function(config) {
      if (config.data) {
        config.data.currency = currency;
      }
      return config;
    },
    function(error) {
      return Promise.reject(error);
    }
  );
};

configureCurrency("eur");

export const axiosWP = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL_NEWS
});

export default axios3C;

export const axiosBase = axios.create();
