<template>
  <div class="text-center col-md-3 col-sm-6 card-wrapper">
    <div class="name">
      <span title="Manufacturer Part Number">{{ data.name }}</span>
      <span title="Qty. Available in Stock"></span>
    </div>
    <router-link :to="`/${data.urlcomponent}`">
      <picture>
        <source
          v-if="data.photos && data.photos.length"
          :srcset="data.photos[0].url"
          class="img"
        />
        <source
          class="img"
          type="image/webp"
          srcset="../../assets/photo-holder.webp"
        />
        <img
          class="img"
          src="../../assets/photo-holder.png"
          alt="Product photo"
        />
      </picture>
    </router-link>
    <div class="description-wrapper">
      <p class="card-text description">{{ data.description }}</p>
    </div>
    <ul v-if="data.price">
      <li>
        <div
          v-if="!data.price.promotion"
          class="price"
          title="Net Price for 1 pcs."
        >
          {{ price }} {{ data.price.symbol }}
        </div>
        <div v-else class="price price-with-promo text-center mb-6 mt-2">
          <span class="new"
            >{{ data.price.grossprice }} {{ data.price.symbol }}</span
          >
          <span class="old"
            >{{ data.price.baseprice }} {{ data.price.symbol }}</span
          >
          <p class="old-price-info">
            {{ $t("PROMO.PRICEINFO") }}: {{ data.price.baseprice }}
            {{ data.price.symbol }}
          </p>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import i18n from "./../../i18n";
import config from "./../../3c.config";

export default {
  name: "ItemCard",
  computed: {
    price() {
      let price = this.data.price.baseprice * config.TAX_FLOAT;
      return this.data.price.grossprice || price.toFixed(2);
    }
  },
  props: { data: Object },
  data: () => ({
    i18n
  })
};
</script>

<style lang="scss" scoped>
.card-wrapper {
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 0;
  margin-left: -1px;
  margin-bottom: -1px;
}

.description-wrapper {
  height: 110px;
  padding: 5px 10px;
}

.name {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  padding: 5px;
  color: #666;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
}
.img {
  height: 200px;
  width: 100%;
  object-fit: contain;
}

ul {
  list-style: none;
  padding: 10px 0;
  margin: 0;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.description {
  margin-bottom: 10px;
}

p.card-text.description {
  overflow: hidden;
}
.btn.add-to-cart.icon i:hover {
  color: #ed3730;
}
.btn.add-to-cart.icon i {
  background-color: white;
  color: #333;
}
.btn.add-to-cart.icon {
  font-size: 20px;
  position: absolute;
  right: 10px;
  padding: 0;
  top: 5px;
}

.price-with-promo {
  .old {
    text-decoration: line-through;
    color: gray;
  }
  .new {
    font-weight: bold;
    color: #ff5d03;
    margin-right: 10px;
  }
  .old-price-info {
    font-size: 12px;
    margin: 10px 15px;
  }
}
</style>
