<template>
  <div>
    <div class="header-wrapper-exception acl-banner">
      <h2>ACL Bearings</h2>
      <hr class="line" />
    </div>
    <b-container>
      <div class="grid">
        <div class="text">
          <p>
            ACL has a proud &amp; rich history in Motorsport. There is no better
            proving ground than the racetrack whatever it’s configuration. In
            the heat of competition proven technology always comes through.
          </p>
          <p>
            The ACL RACE Series performance engine bearing range of today has a
            strong pedigree in motorsport &amp; traces its roots back to the
            1960’s with the manufacture of engine bearings for use in the world
            conquering Brabham Formula 1. Further experience in the Australian
            Supercars Championship &amp; numerous other race codes has
            culminated in the current ACL RACE Series technology platform. The
            unique combination of design, metallurgy, engineering &amp;
            precision quality control come together to deliver what engine
            builders expect from performance engine bearings.
          </p>
        </div>
        <div class="image">
          <picture>
            <source
              type="image/webp"
              srcset="./../../assets/brands-logos/p10_acl.webp"
            />
            <img
              src="./../../assets/brands-logos/p10_acl.png"
              alt="ACL Bearings"
            />
          </picture>
        </div>
      </div>
    </b-container>
    <page-header text="Important Links" font="black" back="white"></page-header>
    <b-container>
      <div class="link-items">
        <router-link to="/catalogue/acl">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Catalogue ON-LINE
          </div>
        </router-link>
        <a href="https://ozparts.eu/pdf/RSAG20.pdf" target="_blank">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Catalogue PDF
          </div>
        </a>
        <a href="https://aclperformance.eu/" target="_blank">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Website
          </div>
        </a>
        <a
          href="https://drive.google.com/open?id=1Jb_IprCzcQOPPbcK0U1iAHIY0e8THldk"
          target="_blank"
          v-if="isUserLogged"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Useful Files
            <ul class="link-items-list">
              <li>Price Lists / Guides / Catalogues</li>
              <li>Artwork (Logos / Posters / Banners / Brochures )</li>
              <li>Product images</li>
              <li>Videos</li>
              <li>Technical Support / Fitting Instructions</li>
            </ul>
          </div>
        </a>
      </div>
      <div class="youtube-wrapper">
        <div class="youtube">
          <a
            href="https://www.facebook.com/ACLRaceSeries/videos/464323550753461/"
            target="_blank"
          >
            <picture>
              <source
                type="image/webp"
                srcset="./../../assets/acl_video.webp"
              />
              <img src="./../../assets/acl_video.jpg" alt="ACL video" />
            </picture>
          </a>
        </div>
        <div>
          <p>ACL Race Series</p>
          <p>
            We're proud to be a long-term partner of Kelly Racing. Check out
            Todd Kelly talking about the relationship they have with ACL!
          </p>
        </div>
      </div>
      <brands class="brands" />
    </b-container>
  </div>
</template>

<script>
import Brands from "@/components/brands/Brands.vue";
import PageHeader from "@/components/common/PageHeader.vue";
import { mapGetters } from "vuex";

export default {
  name: "AclEn",
  components: { Brands, PageHeader },
  computed: {
    ...mapGetters(["isUserLogged"])
  }
};
</script>
