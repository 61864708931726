<template>
  <div class="news-item-wrapper">
    <div class="news">
      <router-link
        tag="div"
        :to="'/news/' + news.post_name"
        class="image"
        v-if="news.img_url"
      >
        <img :src="news.img_url" :alt="news.post_title" />
      </router-link>
      <div class="text-wrapper">
        <div class="text">
          <router-link
            class="news-title"
            :to="'/news/' + news.post_name"
            tag="h2"
            >{{ news.post_title }}</router-link
          >
          <div class="content">
            {{ news.content && news.content.main }}
          </div>
          <div class="date">{{ news.post_date | date }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewsItem",
  props: {
    news: Object
  },
  filters: {
    date(value) {
      return new Date(value).toDateString();
    }
  }
};
</script>

<style lang="scss">
.news-item-wrapper {
  margin: 20px 0;
  .news:hover {
    cursor: pointer;
  }

  .image {
    display: flex;
    justify-content: center;

    img {
      width: 60%;
      object-fit: scale-down;
      margin-bottom: 20px;
    }
  }

  .date {
    color: gray;
    margin-top: 10px;
    font-size: 0.8rem;
  }

  .text-wrapper {
    display: flex;
    justify-content: center;
  }

  .text {
    z-index: 1;
    font-family: "Montserrat", sans-serif;
    line-height: 1.5;
    font-size: 15px;
    text-align: right;

    h2 {
      margin-bottom: 1em;
      z-index: 1;
      font-family: "Michroma", sans-serif;
      word-spacing: 5px;
      line-height: 1.5;

      font-size: 17px;
    }
  }

  @media screen and (min-width: 960px) {
    .text-wrapper {
      display: flex;
      justify-content: flex-start;
    }

    .text {
      padding-bottom: 100px;
      padding-right: 2em;
      padding-left: 0;
    }
  }
}
</style>
