<template>
  <div>
    <div class="header-wrapper-exception dba-banner">
      <h2>Disc Brakes Australia</h2>
      <hr class="line" />
    </div>
    <b-container>
      <div class="grid">
        <div class="text">
          <p>
            Ponad czterdzieści lat doświadczenia w produkcji i niezrównanej
            wiedzy dzięki badaniom i rozwojowi, marka DBA zyskała pozycję
            najbardziej utytułowanego australijskiego producenta i światowego
            lidera w produkcji tarcz hamulcowych.
          </p>
          <p>
            Dzięki ciągłemu doskonaleniu technologii i pragnieniu innowacji, DBA
            udało się zdefiniować na nowo i dostarczyć tarcze hamulcowe dla
            najróżniejszych zastosowań w pojazdach i w szerokim wachlarzu
            warunków. W przypadku tarcz hamulcowych, jak w przypadku wszystkich
            elementów bezpieczeństwa, nie może być skrótów. Kierowcy na całym
            świecie cenią produkty DBA za konsekwencję, niezawodność i doskonałe
            osiągi. DBA gwarantuje to poprzez konstruowanie hamulców tarczowych,
            które są znacznie lepsze od hamulców standardowych i zamienników.
            Jak? DBA stawia sobie za cel zapewnienie wysokiej jakości,
            dokładności, najlepszych metali i urządzeń produkcyjnych, które
            umożliwiają produkcję najwyższej jakości tarcz hamulcowych.
          </p>
        </div>
        <div class="image">
          <picture>
            <source
              type="image/webp"
              srcset="./../../assets/brands-logos/p10_dba.webp"
            />
            <img
              src="./../../assets/brands-logos/p10_dba.png"
              alt="Disc Brakes Australia"
            />
          </picture>
        </div>
      </div>
    </b-container>
    <page-header
      :text="$t('importantLinks')"
      font="black"
      back="white"
    ></page-header>
    <b-container class="my-container">
      <div class="link-items">
        <router-link to="/catalogue/dba">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>
            {{ $t("catalogue") }} ON-LINE
          </div>
        </router-link>
        <a
          href="https://drive.google.com/file/d/16YjRZ292R8gol0YGt8lIYT2cOm-pMmL7/view?usp=sharing"
          target="_blank"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>
            {{ $t("catalogue") }} PDF
          </div>
        </a>
        <a href="https://www.dbabrakes.eu/" target="_blank">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>
            {{ $t("website") }}
          </div>
        </a>
        <a
          v-if="isUserLogged"
          :href="
            $source === 'ozpartseu'
              ? 'https://drive.google.com/drive/folders/1pfMqdBzduU4anqSg7-FA_XyJllI_0_bl'
              : 'https://drive.google.com/open?id=1XwWVBdl61ob_VvHarqIf9YXXoSb0eEIM'
          "
          target="_blank"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>
            {{ $t("usefulFiles") }}
            <ul class="link-items-list">
              <li>{{ $t("usefulFiles1") }}</li>
              <li>{{ $t("usefulFiles2") }}</li>
              <li>{{ $t("usefulFiles3") }}</li>
              <li>{{ $t("usefulFiles4") }}</li>
              <li>{{ $t("usefulFiles5") }}</li>
            </ul>
          </div>
        </a>
        <a
          href="https://www.youtube.com/channel/UCRbJZlGuvpX5kZA7aoLMbEw?fbclid=IwAR0kxHTXwnVxJTf2IzItaNOTEgoM1ISkXt4ScILDRzeoFnNB1vhb5B4BIH4"
          target="_blank"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>
            {{ $t("youtube") }}
          </div>
        </a>
      </div>
      <!-- <div class="youtube-wrapper">
        <div class="youtube">
          <iframe
            width="100%"
            height="360"
            src="https://www.youtube.com/embed/GeQ4Qwv-dT8"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div>
          <p>DBA Toyota Landcruiser 200 Series 4WD Stopping Distance Test</p>
          <p>Strangely enough, brake upgrades are often overlooked when it comes to 4WD applications. Although, it is not only a necessity as far hauling around heavy weight goes, but also one of the simplest, cheapest and most effective upgrades you can make to your 4WD.</p>
          <p>We upgraded a 200 Series Toyota Landcruiser with DBA 4000 T3 series rotors, DS Performance brake pads and Ferodo Performance DOT5.1 brake fluid. Then we pitted our upgrade against the stock OEM brake system to see just how much we could reduce emergency stopping distances.</p>
          <p>For more info visit www.dba.com.au</p>
        </div>
      </div>-->
      <brands class="brands" />
    </b-container>
  </div>
</template>

<script>
import Brands from "@/components/brands/Brands.vue";
import PageHeader from "@/components/common/PageHeader.vue";
import { mapGetters } from "vuex";

export default {
  name: "BrandDbaPl",
  components: { Brands, PageHeader },
  computed: {
    ...mapGetters(["isUserLogged"])
  }
};
</script>
