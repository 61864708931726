<template>
  <section class="contact-info-wrapper">
    <h3 class="main-item-header">{{ $t("BASKET.CONTACT_DETAILS") }}</h3>
    <div class="grid">
      <b-form-group :label="$t('COMMON.FIRST_NAME')" label-sr-only>
        <b-form-input
          id="first-name"
          v-validate="'required'"
          type="text"
          :placeholder="$t('COMMON.FIRST_NAME')"
          name="firstName"
          :value="value.firstName"
          :state="validateState('firstName')"
          @change="val => handleChange(val, 'firstName')"
        ></b-form-input>
        <b-form-invalid-feedback id="fisrt-name-live-feedback"
          >{{ veeErrors.first("firstName") }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group :label="$t('COMMON.LAST_NAME')" label-sr-only>
        <b-form-input
          id="last-name"
          v-validate="'required'"
          type="text"
          :placeholder="$t('COMMON.LAST_NAME')"
          name="lastName"
          :value="value.lastName"
          :state="validateState('lastName')"
          @change="val => handleChange(val, 'lastName')"
        ></b-form-input>
        <b-form-invalid-feedback id="last-name-live-feedback"
          >{{ veeErrors.first("lastName") }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group :label="$t('COMMON.COMPANY_OPTIONAL')" label-sr-only>
        <b-form-input
          id="company-name"
          type="text"
          :placeholder="$t('COMMON.COMPANY_OPTIONAL')"
          :value="value.companyName"
          @change="val => handleChange(val, 'companyName')"
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Email" label-sr-only>
        <b-form-input
          id="contact-input-2"
          ref="email"
          v-validate="'required|email'"
          type="email"
          name="email"
          data-vv-as="email"
          placeholder="Email"
          :state="validateState('email')"
          :value="value.email"
          @change="val => handleChange(val, 'email')"
        ></b-form-input>
        <b-form-invalid-feedback id="contact-input-1-live-feedback"
          >{{ veeErrors.first("email") }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group :label="$t('COMMON.EMAIL_REPEAT')" label-sr-only>
        <b-form-input
          id="contact-input-3"
          v-model="repeat"
          v-validate="'required|email|confirmed:email'"
          type="email"
          name="repeat"
          :placeholder="$t('COMMON.EMAIL_REPEAT')"
          :state="validateState('repeat')"
          @change="val => handleChange(val, 'repeat')"
        ></b-form-input>
        <b-form-invalid-feedback id="contact-input-3-live-feedback"
          >{{ veeErrors.first("repeat") }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group :label="$t('COMMON.PHONE')" label-sr-only>
        <b-form-input
          id="contact-input-4"
          type="text"
          :placeholder="$t('COMMON.PHONE')"
          :value="value.phone"
          @change="val => handleChange(val, 'phone')"
        ></b-form-input>
      </b-form-group>
    </div>
  </section>
</template>

<script>
export default {
  name: "ContactInfo",
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      repeat: ""
    };
  },
  methods: {
    handleChange(value, field) {
      this.$emit("input", { ...this.value, [field]: value });
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    }
  }
};
</script>

<style scoped lang="scss">
.main-item-header {
  background-color: black;
  color: white;
  font-size: 16px;
  padding: 5px 15px;
  margin-top: 20px;
  margin-bottom: 30px;
  font-family: "Michroma", sans-serif;
}

.selected {
  line-height: 12px;
  padding-left: 40px;

  @media screen and (max-width: 960px) {
    padding-left: 0;
    padding-top: 20px;
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;

  @media screen and (max-width: 960px) {
    grid-template-columns: 1fr;
  }
}
</style>
