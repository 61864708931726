<template>
  <div>
    <div class="additional">
      <div class="additional-header">{{ $t("ORDER.PAY_BY_PAYPAL") }}</div>
      <div id="pp"></div>
    </div>
    <modal-loader v-model="modalLoader" />
  </div>
</template>

<script>
import ModalLoader from "@/components/common/ModalLoader.vue";

export default {
  name: "PayPal",
  props: { order: Object },
  components: { ModalLoader },
  data() {
    return {
      modalLoader: false
    };
  },
  async mounted() {
    const currency = this.order.currency.iso;
    const hostname = window.location.hostname;
    const dev = ["dev.ozperformance.eu", "localhost"];
    const isDev = dev.includes(hostname);
    const token =
      currency === "PLN"
        ? process.env.VUE_APP_PAYPAL_TOKEN_PLN
        : process.env.VUE_APP_PAYPAL_TOKEN_EUR;
    const src = `https://www.paypal.com/sdk/js?client-id=${token}&currency=${currency}&disable-funding=card`;
    await this.$loadScript(src);
    const object = {
      purchase_units: [
        {
          amount: {
            value: isDev ? "0.01" : this.order.grossamount,
            currency_code: currency
          }
        }
      ]
    };
    // eslint-disable-next-line no-undef
    paypal
      .Buttons({
        createOrder: (data, actions) => actions.order.create(object),
        onApprove: (data, actions) => {
          this.modalLoader = true;
          return actions.order
            .capture()
            .then(details => {
              this.$emit("success", details);
            })
            .catch(error => {
              this.$emit("error", error);
            })
            .finally(() => {
              this.modalLoader = false;
            });
        }
      })
      .render("#pp");
  }
};
</script>

<style lang="scss" scoped>
.additional {
  .additional-header {
    background-color: black;
    color: white;
    padding: 5px 15px;
    font-family: "Michroma", sans-serif;
    font-size: 14px;
    margin: 20px 0;
  }
}
</style>
