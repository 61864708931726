<template>
  <div>
    <div class="header-wrapper-exception turbosmart-banner">
      <h2>Turbosmart</h2>
      <hr class="line" />
    </div>
    <b-container>
      <div class="grid">
        <div class="text">
          <p>
            Turbosmart jest jednym z czołowych producentów akcesoriów do turbo
            na świecie. Coraz więcej zespołów wyścigowych i zakładów tuningowych
            na całym świecie używa i poleca produkty Turbosmart.
          </p>
          <p>
            Ich osiągnięcia na torze i podczas imprez ulicznych są
            potwierdzeniem zasad projektowania, produkcji i testowania wyrobów
            Turbosmart. Informacje zwrotne otrzymane od zawodników i tunerów
            przyczyniają się do ciągłego rozwoju produktów Turbosmart,
            wzmacniając filozofię ciągłego doskonalenia.
          </p>
        </div>
        <div class="image">
          <picture>
            <source
              type="image/webp"
              srcset="./../../assets/brands-logos/p10_turbosmart.webp"
            />
            <img
              src="./../../assets/brands-logos/p10_turbosmart.png"
              alt="Disc Brakes Australia"
            />
          </picture>
        </div>
      </div>
    </b-container>
    <page-header
      :text="$t('importantLinks')"
      font="black"
      back="white"
    ></page-header>
    <b-container class="my-container">
      <div class="link-items">
        <router-link to="/catalogue/turbosmart">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>
            {{ $t("catalogue") }} ON-LINE
          </div>
        </router-link>
        <a
          href="https://drive.google.com/file/d/1BxqIuAw4uZaBOUtfXl9lBhE8216x3oqs/view"
          target="_blank"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>
            {{ $t("catalogue") }} PDF
          </div>
        </a>
        <a href="http://www.turbosmart.com/" target="_blank">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>
            {{ $t("website") }}
          </div>
        </a>
        <a
          href="https://drive.google.com/drive/folders/1ZhmfhAH-Wx9Jx-pmHtnnLPIpqLALxoL1"
          target="_blank"
          v-if="isUserLogged"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>
            {{ $t("usefulFiles") }}
            <ul class="link-items-list">
              <li>{{ $t("usefulFiles1") }}</li>
              <li>{{ $t("usefulFiles2") }}</li>
              <li>{{ $t("usefulFiles3") }}</li>
              <li>{{ $t("usefulFiles4") }}</li>
              <li>{{ $t("usefulFiles5") }}</li>
            </ul>
          </div>
        </a>
        <a
          href="https://www.youtube.com/channel/UCiPdbaflth_3zXeQMDHDTEg"
          target="_blank"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>
            {{ $t("youtube") }}
          </div>
        </a>
      </div>
      <!-- <div class="youtube-wrapper">
        <div class="youtube">
          <iframe
            width="100%"
            height="360"
            src="https://www.youtube.com/embed/7xQO_R87jVc"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div>
          <p>Turbosmart Ford Fiesta ST - Kompact Shortie Dual Port Blow-Off Valve Sound</p>
          <p>Hear the glorious sound of the Turbosmart Kompact Shortie Dual Port blow-off valve (TS-0203-1066), fitted to the Turbosmart Ford Fiesta ST. The Kompact Shortie may be small, but it's anything but small on sound!</p>
        </div>
      </div>-->
      <brands class="brands" />
    </b-container>
  </div>
</template>

<script>
import Brands from "@/components/brands/Brands.vue";
import PageHeader from "@/components/common/PageHeader.vue";
import { mapGetters } from "vuex";

export default {
  name: "TurbosmartPl",
  components: { Brands, PageHeader },
  computed: {
    ...mapGetters(["isUserLogged"])
  }
};
</script>
