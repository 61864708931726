const dba = require("./assets/kolecka/Kolecka_DBA.png");
const dbaWebp = require("./assets/kolecka/Kolecka_DBA.webp");
const xp = require("./assets/kolecka/XP_1.png");
const xpWebp = require("./assets/kolecka/XP_1.webp");
const hawk = require("./assets/kolecka/o-hawk.jpg");
const hawkWebp = require("./assets/kolecka/o-hawk.webp");
const xforce = require("./assets/kolecka/xforce1_lighter2.png");
const xforceWebp = require("./assets/kolecka/xforce1_lighter2.webp");
const pedders = require("./assets/kolecka/o-pedders.jpg");
const peddersWebp = require("./assets/kolecka/o-pedders.webp");
const turbosmart = require("./assets/kolecka/o-turbosmart.jpg");
const turbosmartWebp = require("./assets/kolecka/o-turbosmart.webp");
const whiteline = require("./assets/kolecka/o-whiteline.jpg");
const whitelineWebp = require("./assets/kolecka/o-whiteline.webp");
const acl = require("./assets/kolecka/o-acl.png");
const aclWebp = require("./assets/kolecka/o-acl.webp");
const aeroflow = require("./assets/kolecka/Aeroflow_Ozparts_page.png");
const aeroflowWebp = require("./assets/kolecka/Aeroflow_Ozparts_page.webp");

export const LOCATION = {
  EU: "5e0dbaba9e33df43f0b3a495"
};

export const brands = [
  {
    header: "dba",
    img: dba,
    imgWebp: dbaWebp,
    showInDeadstock: true
  },
  {
    header: "xclutch",
    img: xp,
    imgWebp: xpWebp,
    showInDeadstock: true
  },
  {
    header: "hawk",
    img: hawk,
    imgWebp: hawkWebp,
    showInDeadstock: true
  },
  {
    header: "xforce",
    img: xforce,
    imgWebp: xforceWebp,
    showInDeadstock: true
  },
  {
    header: "pedders",
    img: pedders,
    imgWebp: peddersWebp,
    showInDeadstock: true
  },
  {
    header: "turbosmart",
    img: turbosmart,
    imgWebp: turbosmartWebp
  },
  {
    header: "whiteline",
    img: whiteline,
    imgWebp: whitelineWebp,
    showInDeadstock: true
  },
  {
    header: "acl",
    img: acl,
    imgWebp: aclWebp,
    showInDeadstock: true
  },
  {
    header: "aeroflow",
    img: aeroflow,
    imgWebp: aeroflowWebp,
    showInDeadstock: true
  }
];

export const MANUFACTURER = {
  ACS: "5f9bda55dcdf6b0c04f18789",
  DBA: "5f9bda55dcdf6b0c04f1878c",
  PEDDERS: "5f9bda55dcdf6b0c04f1878b",
  XFORCE: "5f9bda55dcdf6b0c04f1878d",
  HAWK: "5f9bda56dcdf6b0c04f18790",
  TURBOSMART: "5f9bda56dcdf6b0c04f18791",
  ACL: "5f9bda56dcdf6b0c04f1878e",
  WHITELINE: "5f9bda55dcdf6b0c04f1878a",
  REDARC: "5f9bdaf85e3dc023c4682867",
  SABER: "60c063843f41452f00573370",
  EUROSPARES: "5f9bda56dcdf6b0c04f18792",
  OSRAM: "5f9bda56dcdf6b0c04f18793",
  OTHER: "5f9bda56dcdf6b0c04f18794",
  AEROFLOW: "60c0672c3f41452f00573373"
};
