<!--suppress HtmlUnknownTarget -->
<template>
  <div class="upper-part">
    <div class="content d-flex">
      <div class="logo">
        <router-link to="/">
          <picture>
            <source
              type="image/webp"
              srcset="./../../../assets/Ozperformance_logo_outlined.webp"
            />
            <img :src="logo" alt="Ozperformance" />
          </picture>
        </router-link>
      </div>
      <v-spacer />
      <div class="buttons">
        <v-btn text small class="nav-button-main" to="/basket">
          <v-icon class="nav-icon" size="20">mdi-cart</v-icon>
          <v-badge right bottom color="primary">
            <span slot="badge">{{ basketQuantity || 0 }}</span>
            <span class="basket-text">{{ $t("basket") }}</span>
          </v-badge>
        </v-btn>
      </div>
      <search-component />
      <locale-component />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import LocaleComponent from "./upper/Locale";
import SearchComponent from "./upper/Search";

const logo = require("./../../../assets/Ozperformance_logo_outlined.png");

export default {
  name: "NavUpperPart",
  data: () => ({
    logo
  }),
  computed: {
    ...mapState(["basketQuantity"])
  },
  components: {
    LocaleComponent,
    SearchComponent
  }
};
</script>

<style lang="scss" scoped>
.logo {
  img {
    width: 400px;
  }
}

.buttons {
  .nav-icon {
    margin-right: 0.5em;
    font-size: 1.5em;
  }

  .nav-button-main {
    text-transform: unset;
    letter-spacing: 0.5px;
    font-family: "Montserrat", sans-serif;
    margin-right: 1em;

    &:hover {
      text-decoration: none;
    }
  }
}

.basket-text {
  display: none;
}

@media (min-width: 1200px) {
  .basket-text {
    display: block;
  }
}
</style>
