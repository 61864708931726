<template>
  <div class="order-wrapper">
    <div class="loader" v-if="loader">
      <v-progress-circular indeterminate></v-progress-circular>
    </div>
    <div class="order-content" v-if="!loader">
      <b-container class="my-container">
        <v-alert class="alert" type="success" outlined :value="showSuccess"
          >{{ $t("ORDER.PAID") }}
        </v-alert>
        <v-alert class="alert" type="error" outlined :value="showCancel"
          >{{ $t("ORDER.CANCELLED") }}
        </v-alert>
        <div class="date">
          <div>
            {{ $t("ORDER.DATE") }}:
            {{ new Date(order.date).toLocaleDateString() }}
          </div>
        </div>
        <div class="order-grid">
          <div>
            <div class="shipment">
              <div class="shipment-header">
                {{ $t("ORDER.PRODUCTS_PENDING_SHIPMENT") }} ({{
                  productsQuantity
                }})
              </div>
              <div class="shipment-items">
                <div v-for="item in linesToRender" :key="item._id">
                  <order-item
                    :item="item"
                    :currencySymbol="order.currency.symbol"
                  ></order-item>
                  <div class="line">
                    <hr />
                  </div>
                </div>
              </div>
            </div>
            <div class="payment">
              <div class="payment-header">
                {{ $t("ORDER.PAYMENT_INFORMATION") }}
              </div>
              <div class="payment-content">
                <div class="payment-grid">
                  <div>
                    <p>
                      <strong>{{ $t("ORDER.BILLING_ADDRESS") }}</strong>
                    </p>
                    <p class="address">
                      {{ order.billaddressee }}
                      <br />
                      {{ order.billaddress }}
                      <br />
                      <span v-if="order.billaddress2">
                        {{ order.billaddress2 }}
                        <br />
                      </span>
                      {{ order.billzip }}, {{ order.billcity }}
                      <br />
                      {{ order.billcountry }}
                      <br />
                    </p>
                  </div>
                  <div>
                    <p>
                      <strong>{{ $t("ORDER.SHIPPING_ADDRESS") }}</strong>
                    </p>
                    <p class="address">
                      {{ order.shipaddressee }}
                      <br />
                      {{ order.shipaddress }}
                      <br />
                      <span v-if="order.shipaddress2">
                        {{ order.shipaddress2 }}
                        <br />
                      </span>
                      {{ order.shipzip }}, {{ order.shipcity }}
                      <br />
                      {{ order.shipcountry }}
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <pay-pal
              v-show="
                showWidget && paymentMethodId === '607468324e5b797a767fe87d'
              "
              :order="order"
              @success="success"
              @error="error"
            />
            <revolut
              v-show="showWidget && paymentMethodId === 'revolut'"
              :order="order"
              @success="success"
              @error="error"
            />
            <div v-show="order && order.paymentstatus" class="payment">
              <div class="payment-header payment-status">
                {{ $t("ORDER.PAYMENT_STATUS_INFO") }}
                - {{ order.name }}
              </div>
            </div>
            <div class="additional" v-if="order.comment">
              <div class="additional-header">
                {{ $t("ORDER.ADDITIONAL_INFO") }}
              </div>
              <div>{{ order.comment }}</div>
            </div>
          </div>
          <order-side-info :order="order" />
        </div>
      </b-container>
    </div>

    <universal-modal :modal="modal" @closeModal="modal.show = false" />
  </div>
</template>

<script>
import axios3C from "../axios";
import OrderItem from "@/components/order/OrderItem.vue";
import PayPal from "@/components/order/PayPal.vue";
import UniversalModal from "@/components/common/UniversalModal.vue";
import Revolut from "@/components/order/Revolut";
import OrderSideInfo from "@/components/order/OrderSideInfo";

export default {
  name: "OrderView",
  components: { Revolut, OrderItem, UniversalModal, PayPal, OrderSideInfo },
  computed: {
    paymentMethodId() {
      return (
        this.order && this.order.paymentmethod && this.order.paymentmethod._id
      );
    },
    showSuccess() {
      return this.orderStatus === "success";
    },
    showCancel() {
      return this.orderStatus === "cancel";
    },
    orderStatus() {
      return this.$route.params.status;
    },
    url() {
      return window.location.href;
    },
    filteredProducts() {
      return this.order.transactionlines.filter(line => line.amount);
    },
    linesToRender() {
      return this.filteredProducts;
    },
    productsQuantity() {
      let quantity = 0;
      this.filteredProducts.forEach(e => {
        quantity += e.quantity;
      });
      return quantity;
    },
    showWidget() {
      return !(
        this.order.status._id === "closed" ||
        this.order.status._id !== "pendingapproval" ||
        this.order.paymentstatus
      );
    }
  },
  data() {
    return {
      loading: false,
      order: null,
      loader: true,
      modal: {
        show: false,
        header: "",
        text: "",
        code: ""
      }
    };
  },
  methods: {
    confirmPayment() {
      axios3C
        .post("/cart", {
          action: "setPaymentStatus",
          transaction_id: this.$route.params.id,
          customer: "guest"
        })
        .catch(err => {
          this.error(err);
        });
    },
    success(details) {
      if (details.status === "COMPLETED") {
        this.$gtag.config("AW-11010423991");
        this.$gtag.event("conversion", {
          send_to: "AW-11010423991/SyKiCISmoYAYELf5loIp",
          value: this.order.grossamount,
          currency: this.order.currency.iso,
          transaction_id: this.order.displayname
        });
        this.confirmPayment();
        this.modal.header = this.$t("ORDER.PAYMENT_COMPLETE");
        this.modal.text = this.$t("ORDER.PAYMENT_COMPLETE_DSC");
        this.showWidget = false;
      } else {
        this.modal.header = this.$t("ORDER.payment_status");
        this.modal.text = details.status;
      }
      this.modal.show = true;
    },
    error(err) {
      const error = (err.response && err.response.data) || err;
      if (error.code === "AUTH001") {
        this.$store.commit("logOut");
      } else {
        this.modal.header = this.$t("ORDER.SERVER_ERROR");
        this.modal.text = error.message || error.errmsg || error;
        this.modal.code = error.code;
        this.modal.show = true;
      }
    }
  },
  mounted() {
    this.$store.commit("clearCart");
    this.loader = true;
    axios3C
      .post("/transactions", {
        action: "getTransactions",
        transaction_id: this.$route.params.id,
        customer: "guest"
      })
      .then(res => {
        this.order = res.data;
        if (
          this.order.status._id === "closed" &&
          this.order.status._id !== "pendingapproval" &&
          this.paymentstatus
        ) {
          this.showWidget = false;
        }
      })
      .catch(err => {
        this.error(err);
      })
      .finally(() => {
        this.loader = false;
      });
  }
};
</script>

<style lang="scss">
.link {
  margin: 20px 0;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
}

.download-button {
  text-align: center;
}

.address {
  line-height: 18px;
}

.order-wrapper {
  padding: 20px;

  .alert {
    margin-bottom: 20px;
  }

  .loader {
    text-align: center;
  }

  .date {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }

  .additional {
    .additional-header {
      background-color: black;
      color: white;
      padding: 5px 15px;
      font-family: "Michroma", sans-serif;
      font-size: 14px;
      margin: 20px 0;
    }
  }

  .order-grid {
    @media screen and (min-width: 906px) {
      display: grid;
      grid-template-columns: 2fr 1fr;
      grid-column-gap: 30px;
    }

    .side-table {
      position: sticky;
      top: 20px;
      margin-top: 20px;
      border-top: 1px solid rgba(0, 0, 0, 0.125);
      border-left: 1px solid rgba(0, 0, 0, 0.125);
      border-right: 1px solid rgba(0, 0, 0, 0.125);

      .table-item {
        border-bottom: 1px solid rgba(0, 0, 0, 0.125);
        padding: 12px 20px;
      }

      .opposite {
        display: flex;
        justify-content: space-between;
      }
    }

    .payment {
      margin-top: 20px;

      .payment-header {
        background-color: black;
        color: white;
        padding: 5px 15px;
        font-family: "Michroma", sans-serif;
        font-size: 14px;
        margin-bottom: 20px;
      }
      .payment-status {
        background-color: lightgreen;
        color: black;
        font-weight: 700;
        font-size: 16px;
      }
      .payment-grid {
        padding-top: 20px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 20px;
        line-height: 0.8em;

        @media screen and (max-width: 960px) {
          grid-template-columns: 1fr;
        }
      }

      .v-input__slot {
        background-color: #fafafa;
      }
    }

    .shipment {
      margin-top: 20px;
      border: 1px solid rgba(0, 0, 0, 0.125);

      .line {
        width: 90%;
        margin: 0 auto;
        hr {
          margin: 8px 0 0;
        }
      }

      .shipment-header {
        background-color: black;
        color: white;
        padding: 5px 15px;
        font-family: "Michroma", sans-serif;
        font-size: 14px;
      }
    }
  }
}
</style>
