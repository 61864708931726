<template>
  <div class="text-xs-center ">
    <v-dialog v-model="dialog" width="515" persistent>
      <v-card>
        <v-card-title class="headline grey lighten-2 header" primary-title>
          {{ $t("CURRENCY.TITLE") }}
        </v-card-title>
        <v-card-text>
          <p class="body">
            {{ $t("CURRENCY.BODY_LOCALE.A") }}
            <span class="languageVersion">
              {{ $t("CURRENCY.BODY_LOCALE.A_VER") }}</span
            >
            {{ $t("CURRENCY.BODY_LOCALE.B") }}
            <span class="languageVersion">
              {{ languageVersion !== "pl" ? "EUR (Euro)" : "PLN (złotówki)?" }}
            </span>

            <span v-if="languageVersion !== 'pl'">
              {{ $t("CURRENCY.BODY_LOCALE.C") }}?
            </span>
          </p>
        </v-card-text>
        <v-divider class="divider"></v-divider>
        <v-card-actions>
          <div class="button-container">
            <v-btn
              color="error"
              class="button"
              @click="clickHandler(true, languageVersion)"
            >
              {{ $t("CURRENCY.BUTTON_YES") }}
              {{ languageVersion !== "pl" ? "EUR" : "PLN" }}
            </v-btn>
            <v-btn class="button" @click="clickHandler(false, languageVersion)">
              {{ $t("CURRENCY.BUTTON_NO") }}
              {{ currency === "pln" ? "(PLN)" : "(EUR)" }}
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "ChangeCurrencyLocale",
  props: {
    changeCurrency: Boolean,
    languageVersion: String
  },
  data() {
    return {
      dialog: this.changeCurrency
    };
  },
  computed: {
    ...mapState(["currency"]),

    currencyFormat() {
      return this.languageVersion.toUpperCase();
    }
  },
  methods: {
    clickHandler(val, languageVersion) {
      this.dialog = false;
      this.$emit("clicked", val, languageVersion);
    }
  },
  watch: {
    changeCurrency() {
      this.dialog = this.changeCurrency;
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  font-size: 18px !important;
  word-break: normal;
}

.body {
  font-size: 14px !important;
  margin: 0;
  padding-top: 16px;
}

.divider {
  margin: 0px;
}

.languageVersion {
  font-weight: 700;
}

.button {
  text-transform: none;
  font-size: 14px;
}

.v-dialog > .v-card > .v-card__text {
  padding: 0 24px 16px;
}

.button-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 8px 0;
  justify-content: space-between;
}

@media (min-width: 550px) {
  .header {
    font-size: 20px !important;
  }
  .body,
  .button {
    font-size: 16px !important;
  }
  .button-container {
    flex-direction: row;
  }
}
</style>
