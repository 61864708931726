<template>
  <div>
    <div class="header-wrapper-exception dba-banner">
      <h2>Disc Brakes Australia</h2>
      <hr class="line" />
    </div>
    <b-container>
      <div class="grid">
        <div class="text">
          <p>
            Mit vier Jahrzehnten Produktionserfahrung und unübertroffenem, durch
            Forschung und Entwicklung generiertem Wissen hat sich DBA als
            Australiens meistausgezeichneter und weltweit führender Hersteller
            von Bremsscheiben positioniert.
          </p>
          <p>
            Mit kontinuierlichen technischen Verbesserungen und Innovationsdrang
            ist es DBA gelungen, Bremslösungen für eine breite Palette von
            Fahrzeuganwendungen und -bedingungen neu zu definieren und zu
            liefern. Denn bei Scheibenbremsen gibt es genauso wenig Raum für
            Kompromisse, wie bei allen anderen Sicherheitskomponenten.
            Autofahrer auf der ganzen Welt erwarten von DBA-Produkten eine
            konstante, zuverlässige und überlegene Leistung. DBA stellt dies
            durch die Entwicklung von Scheibenbremsen sicher, die generischen
            Produkten und Nachrüstprodukten weit überlegen sind. Und wie? DBA
            setzt hierzu Maßstäbe in Bezug auf Qualität, Toleranzen, hochwertige
            Metalle und Fertigungsanlagen, die Bremsscheiben von höchster
            Qualität liefern.
          </p>
        </div>
        <div class="image">
          <picture>
            <source
              type="image/webp"
              srcset="./../../assets/brands-logos/p10_dba.webp"
            />
            <img
              src="./../../assets/brands-logos/p10_dba.png"
              alt="Disc Brakes Australia"
            />
          </picture>
        </div>
      </div>
    </b-container>
    <page-header
      :text="$t('importantLinks')"
      font="black"
      back="white"
    ></page-header>
    <b-container class="my-container">
      <div class="link-items">
        <router-link to="/catalogue/dba">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>
            {{ $t("catalogue") }} ON-LINE
          </div>
        </router-link>
        <a
          href="https://drive.google.com/file/d/16YjRZ292R8gol0YGt8lIYT2cOm-pMmL7/view?usp=sharing"
          target="_blank"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>
            {{ $t("catalogue") }} PDF
          </div>
        </a>
        <a href="https://www.dbabrakes.eu/" target="_blank">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>
            {{ $t("website") }}
          </div>
        </a>
        <a
          v-if="isUserLogged"
          :href="
            $source === 'ozpartseu'
              ? 'https://drive.google.com/drive/folders/1pfMqdBzduU4anqSg7-FA_XyJllI_0_bl'
              : 'https://drive.google.com/open?id=1XwWVBdl61ob_VvHarqIf9YXXoSb0eEIM'
          "
          target="_blank"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>
            {{ $t("usefulFiles") }}
            <ul class="link-items-list">
              <li>{{ $t("usefulFiles1") }}</li>
              <li>{{ $t("usefulFiles2") }}</li>
              <li>{{ $t("usefulFiles3") }}</li>
              <li>{{ $t("usefulFiles4") }}</li>
              <li>{{ $t("usefulFiles5") }}</li>
            </ul>
          </div>
        </a>
        <a
          href="https://www.youtube.com/channel/UCRbJZlGuvpX5kZA7aoLMbEw?fbclid=IwAR0kxHTXwnVxJTf2IzItaNOTEgoM1ISkXt4ScILDRzeoFnNB1vhb5B4BIH4"
          target="_blank"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>
            {{ $t("youtube") }}
          </div>
        </a>
      </div>
      <div class="youtube-wrapper">
        <div class="youtube">
          <iframe
            width="100%"
            height="360"
            src="https://www.youtube.com/embed/GeQ4Qwv-dT8"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div>
          <p>DBA Toyota Landcruiser 200 Serie 4WD Bremsweg-Test</p>
          <p>
            Seltsamerweise wird die Bremsen-Upgrade oft übersehen, wenn es um
            4WD-Anwendungen geht. Dabei ist es nicht nur eine Notwendigkeit, um
            schwere Lasten zu transportieren, sondern auch eines der
            einfachsten, billigsten und effektivsten Upgrades, die Sie an Ihrem
            Allradfahrzeug vornehmen können.
          </p>
          <p>
            Wir haben einen Toyota Landcruiser der Serie 200 mit Bremsscheiben
            der Serie DBA 4000 T3, DS Performance-Bremsbelägen und Ferodo
            Performance DOT5.1-Bremsflüssigkeit aufgerüstet. Dann haben wir
            unser Upgrade mit der originalen OEM-Bremsanlage verglichen, um zu
            sehen, wie sehr wir den Notbremsweg verkürzen können.
          </p>
          <p>
            Für weitere Informationen besuchen Sie bitte
            www.australianclutch.com.au.
          </p>
        </div>
      </div>
      <brands class="brands" />
    </b-container>
  </div>
</template>

<script>
import Brands from "@/components/brands/Brands.vue";
import PageHeader from "@/components/common/PageHeader.vue";
import { mapGetters } from "vuex";

export default {
  name: "BrandDbaEn",
  components: { Brands, PageHeader },
  computed: {
    ...mapGetters(["isUserLogged"])
  }
};
</script>
