<template>
  <div>
    <p>{{ symbol }} {{ Number(object.price).toFixed(2) || object.price }}</p>
    <p v-if="object.pricelevel && object.pricelevel.discountpct !== 1">
      Discount: {{ object.pricelevel.name }}
    </p>
  </div>
</template>

<script>
export default {
  name: "ItemPrice",
  props: {
    object: Object,
    symbol: String
  },
  setup() {
    return {};
  }
};
</script>

<style lang="scss" scoped></style>
