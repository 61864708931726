<template>
  <div>
    <b-table
      v-if="applications.length"
      responsive
      striped
      class="applications-table"
      :items="applications"
      :fields="tableFields"
      hover
      selectable
      select-mode="single"
      @row-selected="goToCatalogue"
    />
    <div v-else-if="object.application" v-html="object.application"></div>
  </div>
</template>

<script>
import { useApplications } from "@/composables/useApplications";
export default {
  name: "Applications",
  props: {
    object: {
      type: Object,
      required: true
    },
    applications: {
      type: Array,
      default: () => []
    }
  },
  setup: (props, ctx) => useApplications(props.object.manufacturergroup, ctx)
};
</script>
