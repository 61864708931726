<template>
  <div>
    <div class="header-wrapper-exception pedders-banner">
      <h2>Pedders</h2>
      <hr class="line" />
    </div>
    <b-container>
      <div class="grid">
        <div class="text">
          <p>
            Pedders ist Australiens Nummer 1 im Bereich Aufhängung, Bremsen,
            Abschleppen, Lastentransport und Lenkung für PKWs, Allradfahrzeuge,
            leichte Nutzfahrzeuge und Hochleistungsfahrzeuge und bietet eine
            umfassende Produkt- und Servicepalette.
          </p>
          <p>
            Für jedes neue Fahrgestell entwickelt Pedders eine integrierte und
            technisch ausgereifte Fahrwerkslösung. Ob es sich um Ersatzteile in
            Erstausrüsterqualität, Performance-Teile für PKWs oder robuste
            Offroad-Fahrwerke handelt, sind wir einer der wenigen Hersteller mit
            einer ganzheitlichen Lösung. Die Firma Pedders begann als
            Stoßdämpferhersteller. Heutzutage ist das Unternehmen einzigartig,
            da es ein „System“ anbietet, das unter der Marke Pedders entwickelt,
            herstellt, vertrieben und in Australien auch eingebaut wird. Zu den
            „abgestimmten“ Teilen von Pedders gehören Stoßdämpfer, Federn
            (Spiral-, Blatt- und Drehstabfedern), Buchsen (aus Polyurethan und
            Gummi), Fahrwerksteile, Eisenwaren (sturzverstellbare Montagebolzen,
            Stoßdämpfer, Stabilisatorkomponenten, Halterungen usw.), Querlenker,
            Längslenker, Servolenkungsgetriebe und -pumpen, CV-Gelenke und
            -Wellen und sogar Bremsteile einschließlich Belägen, Reibringen und
            Trommeln. Dies alles sind Produkte der Marke Pedders, die zusammen
            ein SYSTEM bilden.
          </p>
        </div>
        <div class="image">
          <picture>
            <source
              type="image/webp"
              srcset="./../../assets/brands-logos/p10_pedders.webp"
            />
            <img
              src="./../../assets/brands-logos/p10_pedders.png"
              alt="Pedders"
            />
          </picture>
        </div>
      </div>
    </b-container>
    <page-header
      :text="$t('importantLinks')"
      font="black"
      back="white"
    ></page-header>
    <b-container class="my-container">
      <div class="link-items">
        <router-link to="/catalogue/pedders">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Online-Katalog
          </div>
        </router-link>
        <a
          href="https://drive.google.com/file/d/1Nq_49f6W_vmnHLUW6SCeLd2hZ2tQqKsA/view"
          target="_blank"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Pdf-Katalog
          </div>
        </a>
        <a href="https://pedders.eu/" target="_blank">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Webseite
          </div>
        </a>
        <a
          href="https://drive.google.com/drive/folders/1OvK_7qUnNLeilSQEzggerxDBh4BOiz0P"
          target="_blank"
          v-if="isUserLogged"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Useful Files
            <ul class="link-items-list">
              <li>Price Lists / Guides / Catalogues</li>
              <li>Artwork (Logos / Posters / Banners / Brochures )</li>
              <li>Product images</li>
              <li>Videos</li>
              <li>Technical Support / Fitting Instructions</li>
            </ul>
          </div>
        </a>
        <a
          href="https://www.youtube.com/user/PeddersSuspension"
          target="_blank"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Youtube Channel
          </div>
        </a>
      </div>
      <div class="youtube-wrapper">
        <div class="youtube">
          <iframe
            width="100%"
            height="360"
            src="https://www.youtube.com/embed/kxlMVN7_Ix0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div>
          <p>
            Einführung der Pedders Series - Der Einfluss des Gewichts auf ein
            Fahrzeug
          </p>
          <p>
            Was wissen Sie über die Auswirkung von Gewicht auf ein Fahrzeug? Im
            ersten „Virtual Techstop“ von Pedders nehmen wir Sie mit auf eine
            Reise wie nie zuvor. Wir zeigen Ihnen die Themen, Probleme und
            Überlegungen auf, die Ihnen helfen, die beste maßgeschneiderte
            Lösung zu finden.
          </p>
          <p>www.pedders.com.au</p>
        </div>
      </div>
      <brands class="brands" />
    </b-container>
  </div>
</template>

<script>
import Brands from "@/components/brands/Brands.vue";
import PageHeader from "@/components/common/PageHeader.vue";
import { mapGetters } from "vuex";

export default {
  name: "PeddersEn",
  components: { Brands, PageHeader },
  computed: {
    ...mapGetters(["isUserLogged"])
  }
};
</script>
