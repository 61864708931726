<template>
  <div class="order-item-wrapper">
    <div>
      <div class="order-item-content">
        <div class="img">
          <picture>
            <source
              :srcset="item.item.photos[0] && item.item.photos[0].url"
              :alt="item.displayname"
            />
            <source
              type="image/webp"
              srcset="../../assets/photo-holder.webp"
              :alt="item.displayname"
            />
            <img src="../../assets/photo-holder.png" :alt="item.displayname" />
          </picture>
        </div>
        <div>
          <div>
            <router-link :to="`/${item.item.urlcomponent}`"
              >{{ item.item.displayname || item.item.name }}
            </router-link>
          </div>
          <div>{{ item.price }} {{ currencySymbol }}</div>
        </div>
        <div>
          <div>{{ $t("ORDER.QUANTITY") }}: {{ item.quantity }}</div>
          <div>
            {{ $t("ORDER.TOTAL_AMOUNT") }}: {{ item.amount.toFixed(2) }}
            {{ currencySymbol }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderItemComponent",
  props: { item: Object, currencySymbol: String, airfreight: Boolean },
  data: function() {
    return {
      ozpartsInfo: null
    };
  }
};
</script>

<style lang="scss">
.order-item-wrapper {
  padding: 20px 20px 0 20px;

  .order-item-content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 10px;

    @media screen and (max-width: 600px) {
      grid-template-columns: 1fr;
      text-align: center;
    }
  }

  .loader {
    text-align: center;
    padding: 20px;
  }

  .img {
    padding: 10px;
    border: 1px solid lightgray;
    width: 180px;
    height: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 10px auto;

    img {
      max-height: 100px;
      max-width: 160px;
      object-fit: contain;
    }
  }
}
</style>
