<template>
  <div class="icon">
    <a href="https://www.facebook.com/ozperformance/" target="_blank">
      <img
        class="icon-item"
        src="./../../../../assets/facebook.png"
        alt="Facebook"
      />
    </a>
  </div>
</template>

<script>
export default {
  name: "SocialIcons"
};
</script>

<style lang="scss" scoped>
.icon {
  z-index: 200;
  display: flex;
  align-items: center;
}

.icon-item {
  width: 30px;
  z-index: 200;

  &.instagram {
    width: 40px;
  }

  &:hover {
    cursor: pointer;
  }
}
</style>
