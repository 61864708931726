<template>
  <div>
    <div class="header-wrapper-exception hawk-banner">
      <h2>Hawk Performance</h2>
      <hr class="line" />
    </div>

    <b-container>
      <div class="grid">
        <div class="text">
          <p>
            Hawk Performance ist ein führender Anbieter von
            Hochleistungs-Reibbelägen, die in ISO-zertifizierten Betrieben für
            den Motorsport und den Performance-Automobilzubehörmarkt hergestellt
            werden.
          </p>
          <p>
            Die Produkte von Hawk Performance sind so konzipiert, dass die
            Benutzer das Zielterrain für ihre Fahrzeuge kontrollieren,
            beherrschen und erobern können. Jahrzehntelange Erfahrung in der
            Entwicklung von Reibmaterialien für die Luft- und Raumfahrt, die
            Industrie, das Militär, die Automobilindustrie und den Motorsport
            verschafft Hawk Performance einen Vorsprung vor der Konkurrenz. In
            den letzten zehn Jahren haben Hawk Performance-Bremsbeläge mehr
            Motorsport-Meisterschaften gewonnen als jeder andere Hersteller von
            Reibprodukten.
          </p>
        </div>
        <div class="image">
          <picture>
            <source
              type="image/webp"
              srcset="./../../assets/brands-logos/p10_hawk.webp"
            />
            <img src="./../../assets/brands-logos/p10_hawk.png" alt="Hawk" />
          </picture>
        </div>
      </div>
    </b-container>

    <page-header
      :text="$t('importantLinks')"
      font="black"
      back="white"
    ></page-header>

    <b-container>
      <div class="link-items">
        <router-link to="/catalogue/hawk">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Online-Katalog
          </div>
        </router-link>
        <a
          href="https://drive.google.com/file/d/1T_98CLuZQaG6wAl2GBQ8_B37dJUrvdsS/view"
          target="_blank"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Pdf-Katalog
          </div>
        </a>
        <a href="http://www.hawkperformance.com/" target="_blank">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Webseite
          </div>
        </a>
        <a
          :href="
            $source === 'ozpartseu'
              ? 'https://drive.google.com/drive/folders/1HaVf181vCpotxqaa9IJy3yw9Ydi9YkFc'
              : 'https://drive.google.com/open?id=1tgmcFQxng_a5i_4DSyF-fFwVq9UXtV0T'
          "
          target="_blank"
          v-if="isUserLogged"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Useful Files
            <ul class="link-items-list">
              <li>Price Lists / Guides / Catalogues</li>
              <li>Artwork (Logos / Posters / Banners / Brochures )</li>
              <li>Product images</li>
              <li>Videos</li>
              <li>Technical Support / Fitting Instructions</li>
            </ul>
          </div>
        </a>
        <a href="https://www.youtube.com/user/HAWKPERFORMANCE" target="_blank">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Youtube Channel
          </div>
        </a>
      </div>
      <div class="youtube-wrapper">
        <div class="youtube">
          <iframe
            width="100%"
            height="360"
            src="https://www.youtube.com/embed/sttWds7JtWY"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div>
          <p>GOODWOOD Festival der Geschwindigkeit - Budweiser Indy 500 Auto</p>
          <p>
            Wir sind stolz darauf, dass unser 1978er Lightning Cosworth am
            Goodwood Festival of Speed 2014 teilgenommen hat – es ist eine
            ikonische Veranstaltung. Lehnen Sie sich zurück, entspannen Sie sich
            und genießen Sie einen exklusiven Blick auf das Festival of Speed.
          </p>
          <p>*Mit Kopfhörern hören*</p>
        </div>
      </div>
      <brands class="brands" />
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Brands from "@/components/brands/Brands.vue";
import PageHeader from "@/components/common/PageHeader.vue";

export default {
  name: "HawkEn",
  components: { Brands, PageHeader },
  computed: {
    ...mapGetters(["isUserLogged"])
  }
};
</script>
