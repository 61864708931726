<template>
  <div>
    <h3>{{ $t("components") }} {{ base }}</h3>
    <ul>
      <li v-for="item in components" :key="item.id">
        <router-link :to="'/' + item.urlcomponent">
          {{ item.name }} - {{ item.description }}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "KitComponents",
  props: {
    components: Array,
    base: String
  }
};
</script>
