<template>
  <div class="upper-part">
    <div class="content">
      <div class="logo">
        <router-link to="/">
          <picture>
            <source
              type="image/webp"
              srcset="./../../../assets/Ozperformance_logo.webp"
            />
            <img :src="logo" alt="Ozperformance" />
          </picture>
        </router-link>
      </div>
      <div class="icons">
        <mobile-icon text="Basket" to="/basket" icon="mdi-cart" badge />
        <mobile-icon text="Catalogue" to="/catalogue" icon="mdi-book-open" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MobileIcon from "./MobileIcon";

const logo = require("./../../../assets/Ozperformance_logo.png");

export default {
  name: "NavUpperPart",
  data: () => ({
    logo,
    keyword: ""
  }),
  components: { MobileIcon },
  methods: {
    search() {
      if (this.keyword.length > 1) {
        this.$router.push(`/search/${this.keyword}`);
        this.keyword = "";
      }
    },
    logout() {
      this.$store.commit("logOut");
      if (this.$route.name !== "home") {
        this.$router.push("/");
      }
    }
  },
  computed: { ...mapGetters(["getUser", "isUserLogged", "getBasketCount"]) }
};
</script>

<style lang="scss" scoped>
.logo {
  margin: 0 auto;
  width: 200px;
  img {
    width: 100%;
  }
}

.icons {
  margin-top: 20px;
  // display: flex;
  // justify-content: space-between;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 5px;
}

.search {
  margin: 20px 0;
}

.buttons {
  .nav-icon {
    margin-right: 0.5em;
    font-size: 1.5em;
  }

  .nav-button-main {
    text-transform: unset;
    letter-spacing: 0.5px;
    font-family: "Montserrat", sans-serif;
    margin: 10px 0;

    &:hover {
      text-decoration: none;
    }
  }
}
</style>
