<template>
  <div>
    <div class="header-wrapper-exception whiteline-banner">
      <h2>Whiteline</h2>
      <hr class="line" />
    </div>
    <b-container>
      <div class="grid">
        <div class="text">
          <p>
            Whiteline genießt weltweit einen guten Ruf als führender Hersteller
            von Ersatz-, Upgrade- und Preformance-Fahrwerkskomponenten, die die
            Fahrzeugbalance, die Lenkpräzision, die Bodenhaftung und die
            Fahrleistung deutlich verbessern.
          </p>
          <p>
            Die Produkte von Whiteline bestehen aus einstellbaren
            Stabilisatoren, Fahrwerksverstrebungen, Achsvermessungsprodukten und
            Fahrwerksbuchsen-Kits. Sie bieten Kfz-Werkstätten, Tunern,
            Enthusiasten und Rennfahrern innovative Lösungen für Fahrwerks- und
            Handling-Probleme und liefern bewährte Performace- und
            Problemlösungen für viele Fahrzeugplattformen weltweit.
          </p>
        </div>
        <div class="image">
          <picture>
            <source
              type="image/webp"
              srcset="./../../assets/brands-logos/p10_whiteline.webp"
            />
            <img
              src="./../../assets/brands-logos/p10_whiteline.png"
              alt="Whiteline"
            />
          </picture>
        </div>
      </div>
    </b-container>
    <page-header
      :text="$t('importantLinks')"
      font="black"
      back="white"
    ></page-header>
    <b-container>
      <div class="link-items">
        <router-link to="/catalogue/whiteline">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Online-Katalog
          </div>
        </router-link>
        <a
          href="https://drive.google.com/file/d/17H3DcHMDuOAbYAwm9P5g80in3iPopH72/view"
          target="_blank"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Pdf-Katalog
          </div>
        </a>
        <a href="http://whiteline.com.au/" target="_blank">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Webseite
          </div>
        </a>
        <a
          href="https://drive.google.com/drive/folders/1y_peknJX7beJnyGJy7lvFOsH4O2IiiKD"
          target="_blank"
          v-if="isUserLogged"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Useful Files
            <ul class="link-items-list">
              <li>Price Lists / Guides / Catalogues</li>
              <li>Artwork (Logos / Posters / Banners / Brochures )</li>
              <li>Product images</li>
              <li>Videos</li>
              <li>Technical Support / Fitting Instructions</li>
            </ul>
          </div>
        </a>
        <a href="https://www.youtube.com/user/Whitelineflatout" target="_blank">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Youtube Channel
          </div>
        </a>
      </div>
      <div class="youtube-wrapper">
        <div class="youtube">
          <iframe
            width="100%"
            height="360"
            src="https://www.youtube.com/embed/1sOCSAwyfDg"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div>
          <p>Whiteline - Aktivieren Sie mehr Grip - www.whitelineus.com</p>
          <p>
            Whiteline genießt weltweit einen guten Ruf als führender Hersteller
            von Ersatz-, Upgrade- und Preformance-Fahrwerkskomponenten, die die
            Fahrzeugbalance, die Lenkpräzision, die Bodenhaftung und die
            Fahrleistung deutlich verbessern.
          </p>
          <p>
            Die Produkte von Whiteline bestehen aus einstellbaren
            Stabilisatoren, Fahrwerksverstrebungen, Achsvermessungsprodukten und
            Fahrwerksbuchsen-Kits. Sie bieten Kfz-Werkstätten, Tunern,
            Enthusiasten und Rennfahrern innovative Lösungen für Fahrwerks- und
            Handling-Probleme und liefern bewährte Performace- und
            Problemlösungen für viele Fahrzeugplattformen der Welt.
          </p>
        </div>
      </div>
      <brands class="brands" />
    </b-container>
  </div>
</template>

<script>
import Brands from "@/components/brands/Brands.vue";
import PageHeader from "@/components/common/PageHeader.vue";
import { mapGetters } from "vuex";

export default {
  name: "WhitelineEn",
  components: { Brands, PageHeader },
  computed: {
    ...mapGetters(["isUserLogged"])
  }
};
</script>
