<template>
  <div class="brands-wrapper">
    <page-header :text="$t('ourBrands')" font="black" />
    <b-container>
      <div class="brand-items">
        <brand-circle
          v-for="brand in brandsFiltered"
          :key="brand.header"
          :brand="brand"
          class="brand-item"
        />
      </div>
    </b-container>
  </div>
</template>

<script>
import BrandCircle from "@/components/brands/BrandCircle.vue";
import PageHeader from "@/components/common/PageHeader.vue";

const dba = require("./../../assets/kolecka/Kolecka_DBA.png");
const xp = require("./../../assets/kolecka/Kolecka_XP.png");
const hawk = require("./../../assets/kolecka/o-hawk.jpg");
const xforce = require("./../../assets/kolecka/xforce1_lighter2.png");
const pedders = require("./../../assets/kolecka/o-pedders.jpg");
const turbosmart = require("./../../assets/kolecka/o-turbosmart.jpg");
const whiteline = require("./../../assets/kolecka/o-whiteline.jpg");
const acl = require("./../../assets/kolecka/o-acl.png");

const dbaWebp = require("./../../assets/kolecka/Kolecka_DBA.webp");
const xpWebp = require("./../../assets/kolecka/Kolecka_XP.webp");
const hawkWebp = require("./../../assets/kolecka/o-hawk.webp");
const xforceWebp = require("./../../assets/kolecka/xforce1_lighter2.webp");
const peddersWebp = require("./../../assets/kolecka/o-pedders.webp");
const turbosmartWebp = require("./../../assets/kolecka/o-turbosmart.webp");
const whitelineWebp = require("./../../assets/kolecka/o-whiteline.webp");
const aclWebp = require("./../../assets/kolecka/o-acl.webp");

export default {
  name: "BrandsComponent",
  components: { BrandCircle, PageHeader },
  computed: {
    brandsFiltered() {
      return this.brands.filter(
        brand => !(brand.hideOn && brand.hideOn.includes(this.$source))
      );
    }
  },
  data: () => {
    return {
      brands: [
        {
          translationKey: "DBA",
          header: "DBA",
          dsc: "Disc Brakes & Pads",
          img: dba,
          imgWebp: dbaWebp,
          to: "/brands/dba"
        },
        {
          translationKey: "ACS",
          header: "XTREME CLUTCH",
          dsc: "High Performance Clutch & Components",
          img: xp,
          imgWebp: xpWebp,
          to: "/brands/xclutch"
        },
        {
          translationKey: "HAWK",
          header: "HAWK",
          dsc: "Brake Pads",
          img: hawk,
          imgWebp: hawkWebp,
          to: "/brands/hawk"
        },
        {
          translationKey: "XFORCE",
          header: "XFORCE",
          dsc: "Performance Exhaust",
          img: xforce,
          imgWebp: xforceWebp,
          to: "/brands/xforce"
        },
        {
          translationKey: "PEDDERS",
          header: "PEDDERS",
          dsc: "Suspension, Shock Absorbers and Springs",
          img: pedders,
          imgWebp: peddersWebp,
          to: "/brands/pedders",
          hideOn: ["ozpartsuk"]
        },
        {
          translationKey: "TURBOSMART",
          header: "TURBOSMART",
          dsc: "Turbo Performance Products",
          img: turbosmart,
          imgWebp: turbosmartWebp,
          to: "/brands/turbosmart",
          hideOn: ["ozpartsuk"]
        },
        {
          translationKey: "WHITELINE",
          header: "WHITELINE",
          dsc: "Stability Control Components",
          img: whiteline,
          imgWebp: whitelineWebp,
          to: "/brands/whiteline",
          hideOn: ["ozpartsuk"]
        },
        {
          translationKey: "ACL",
          header: "ACL RACE SERIES",
          dsc: "Performance Engine Bearings",
          img: acl,
          imgWebp: aclWebp,
          to: "/brands/acl",
          hideOn: ["ozpartsuk"]
        }
      ]
    };
  }
};
</script>

<style lang="scss">
.brands-wrapper {
  h2 {
    text-align: center;
    text-transform: uppercase;
    word-spacing: 5px;
  }

  .header-wrapper {
    padding-bottom: 0;
  }

  .line {
    display: block;
    height: 1px;
    border: 0;
    border-top: 2px solid black;
    margin: 1em auto;
    padding: 0;
    width: 10em;
  }

  .brand-items {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .brand-item {
      margin: 1.8em;
      width: 220px;
      display: flex;
      justify-content: center;
    }
  }
}
</style>
