<template>
  <div>
    <v-dialog v-model="dialogInner" max-width="400px">
      <v-card>
        <v-card-title class="headline">Send an enquiry</v-card-title>
        <v-card-text>
          <p>
            {{ text }}
          </p>
          <v-form ref="quickContactForm" v-model="valid" lazy-validation>
            <v-text-field
              v-model="email"
              :rules="rules.emailRules"
              label="Your e-mail"
              color="secondary"
              required
            ></v-text-field>

            <v-btn
              :loading="loading"
              :disabled="!valid"
              text
              @click.prevent="sendEnquiry"
              >Send Enquiry</v-btn
            >
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <modal-component
      v-model="modal.show"
      :width="500"
      :text="modal.text"
      :title="modal.title"
      @modal="handleErrorModal"
    />
  </div>
</template>

<script>
import ModalComponent from "@/components/common/Modal.vue";
import { axiosBase } from "../../axios";

export default {
  name: "EnquiryForm",
  components: {
    ModalComponent
  },
  props: {
    part: { type: String, required: true },
    dialog: { type: Boolean, required: true },
    manufacturer: { type: String, default: "" }
  },
  data: () => ({
    email: "",
    valid: true,
    loading: false,
    rules: {
      globalRules: [v => !!v || "This field is required"],
      emailRules: [
        v => !!v || "This field is required",
        v => /.+@.+/.test(v) || "Invalid format"
      ]
    },
    modal: {
      show: false,
      text: "",
      title: ""
    }
  }),
  computed: {
    text() {
      return `Hi, I am interested in ${this.part}. Let me know if this is available.`;
    },
    dialogInner: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.closeModal(val);
      }
    }
  },
  methods: {
    handleErrorModal() {
      this.modal.show = false;
    },
    closeModal(val) {
      this.$emit("modal", val);
    },
    sendEnquiry() {
      const { part, email, manufacturer, text } = this;
      const data = { part, email, manufacturer, text, source: "Ozperformance" };
      const urlBase =
        process.env.NODE_ENV === "production"
          ? "https://api.ozparts.eu"
          : "http://localhost";

      axiosBase({
        method: "POST",
        url: `${urlBase}/email/enquiry-form`,
        data
      })
        .then(() => {
          this.$emit("snack");
        })
        .catch(err => {
          const error = (err.response && err.response.data) || err;
          this.modal.title = "Server error";
          this.modal.text = error.message || error.errmsg || error;
        })
        .finally(() => {
          this.$emit("modal", false);
        });
    }
  }
};
</script>

<style scoped></style>
