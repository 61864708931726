<template>
  <div>
    <div class="header-wrapper-exception hawk-banner">
      <h2>Hawk Performance</h2>
      <hr class="line" />
    </div>

    <b-container>
      <div class="grid">
        <div class="text">
          <p>
            Hawk Performance jest wiodącym dostawcą produktów ciernych o
            najwyższych parametrach użytkowych i wysokiej wydajności,
            przeznaczonych dla sportów motorowych i rynku wtórnego dla
            motoryzacji. Wszystkie części produkowane są w zakładach
            posiadających certyfikat ISO.
          </p>
          <p>
            Produkty Hawk Performance zostały zaprojektowane tak, aby umożliwić
            użytkownikom kontrolę, panowanie i podbijanie terenu, dla którego
            zaprojektowane są ich pojazdy. Dziesięciolecia doświadczeń w
            opracowywaniu materiałów ciernych dla przemysłu lotniczego,
            wojskowego, motoryzacyjnego i sportów motorowych dają Hawk
            Performance przewagę nad konkurencją. W ciągu ostatniej dekady
            produkty cierne Hawk Performance wygrały więcej mistrzostw w
            sportach motorowych niż którykolwiek inny producent materiałów
            ciernych.
          </p>
        </div>
        <div class="image">
          <picture>
            <source
              type="image/webp"
              srcset="./../../assets/brands-logos/p10_hawk.webp"
            />
            <img src="./../../assets/brands-logos/p10_hawk.png" alt="Hawk" />
          </picture>
        </div>
      </div>
    </b-container>

    <page-header
      :text="$t('importantLinks')"
      font="black"
      back="white"
    ></page-header>

    <b-container>
      <div class="link-items">
        <router-link to="/catalogue/hawk">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>
            {{ $t("catalogue") }} ON-LINE
          </div>
        </router-link>
        <a
          href="https://drive.google.com/file/d/1T_98CLuZQaG6wAl2GBQ8_B37dJUrvdsS/view"
          target="_blank"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>
            {{ $t("catalogue") }} PDF
          </div>
        </a>
        <a href="http://www.hawkperformance.com/" target="_blank">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>
            {{ $t("website") }}
          </div>
        </a>
        <a
          :href="
            $source === 'ozpartseu'
              ? 'https://drive.google.com/drive/folders/1HaVf181vCpotxqaa9IJy3yw9Ydi9YkFc'
              : 'https://drive.google.com/open?id=1tgmcFQxng_a5i_4DSyF-fFwVq9UXtV0T'
          "
          target="_blank"
          v-if="isUserLogged"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>
            {{ $t("usefulFiles") }}
            <ul class="link-items-list">
              <li>{{ $t("usefulFiles1") }}</li>
              <li>{{ $t("usefulFiles2") }}</li>
              <li>{{ $t("usefulFiles3") }}</li>
              <li>{{ $t("usefulFiles4") }}</li>
              <li>{{ $t("usefulFiles5") }}</li>
            </ul>
          </div>
        </a>
        <a href="https://www.youtube.com/user/HAWKPERFORMANCE" target="_blank">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>
            {{ $t("youtube") }}
          </div>
        </a>
      </div>
      <!-- <div class="youtube-wrapper">
        <div class="youtube">
          <iframe
            width="100%"
            height="360"
            src="https://www.youtube.com/embed/sttWds7JtWY"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div>
          <p>GOODWOOD Festival of Speed - Budweiser Indy 500 Car</p>
          <p>We are proud to have had our 1978 Lightning Cosworth attend the 2014 Goodwood Festival of Speed, it is such an iconic event. Sit back, relax, and enjoy an exclusive look inside the Festival of Speed.</p>
          <p>*Listen with headphones*</p>
        </div>
      </div>-->
      <brands class="brands" />
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Brands from "@/components/brands/Brands.vue";
import PageHeader from "@/components/common/PageHeader.vue";

export default {
  name: "HawkPl",
  components: { Brands, PageHeader },
  computed: {
    ...mapGetters(["isUserLogged"])
  }
};
</script>
