<template>
  <div class="search" @mouseleave="showSearch = false">
    <v-text-field
      v-if="showSearch"
      single-line
      role="search"
      :label="$t('search')"
      color="secondary"
      class="search-input"
      hide-details="auto"
      v-model="keyword"
      @keyup.enter="search"
      @click:append="search"
    />
    <div class="search-icon" @click="search">
      <v-icon
        left
        class="icon"
        :class="{ active: showSearch }"
        size="25"
        color="black"
        @mouseenter="showSearch = true"
        >mdi-magnify</v-icon
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "Search",
  data: function() {
    return {
      keyword: "",
      showSearch: false
    };
  },
  methods: {
    search() {
      if (this.keyword.length > 1) {
        this.$router.push(`/search/${this.keyword}`);
        this.keyword = "";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.search {
  width: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 0;
  margin: 0;
  margin-left: 20px;
}

.active {
  background-color: #d7d7d7;
  border-radius: 50px;
  height: 30px;
  width: 30px;
}
.search-icon {
  height: 30px;
  width: 30px;
  border-radius: 50px;
  display: flex;
  &:hover {
    cursor: pointer;
  }
}
.icon {
  margin: auto;
}
.search-input {
  width: auto;
  padding: 0;
  margin: 0;
}
</style>

<style lang="scss">
.upper-part {
  .search {
    .v-label {
      font-size: 0.7em;
    }
  }
}
</style>
