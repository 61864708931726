<template>
  <div>
    <v-dialog v-model="dialogInner" max-width="600px">
      <v-card>
        <v-card-title class="headline">{{
          $t("CATALOGUE.QUICK_CONTACT_FORM")
        }}</v-card-title>
        <v-card-text>
          <v-form ref="quickContactForm" v-model="valid" lazy-validation>
            <v-text-field
              v-if="!user"
              v-model="newEmail"
              :rules="rules.emailRules"
              label="E-mail"
              color="secondary"
              required
            ></v-text-field>
            <v-text-field
              v-model="make"
              :rules="rules.globalRules"
              label="Make"
              color="secondary"
              required
            ></v-text-field>
            <v-text-field
              v-model="model"
              :rules="rules.globalRules"
              label="Model"
              color="secondary"
              required
            ></v-text-field>
            <v-text-field
              v-model="year"
              type="number"
              :rules="rules.globalRules"
              label="Year"
              color="secondary"
              required
            ></v-text-field>
            <v-text-field
              v-model="engine"
              :rules="rules.globalRules"
              label="Engine"
              color="secondary"
              required
            ></v-text-field>
            <v-select
              :items="drivingStyles"
              label="Driving style"
              required
              color="secondary"
              :rules="rules.globalRules"
              v-model="drivingStyle"
            ></v-select>
            <v-text-field
              v-if="drivingStyle === 'Track use only'"
              v-model="typeOfCompetition"
              :rules="rules.globalRules"
              label="Competition type"
              color="secondary"
              required
            ></v-text-field>
            <v-btn
              :loading="loading"
              :disabled="!valid"
              text
              @click="sendEnquiry"
              >Send Enquiry</v-btn
            >
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <modal-component
      v-model="modal.show"
      :width="500"
      :text="modal.text"
      :title="modal.title"
      @modal="handleErrorModal"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import ModalComponent from "@/components/common/Modal.vue";
import { quickContactForm } from "./../../../services/emailService";

export default {
  name: "QuickContactForm",
  components: { ModalComponent },
  data: () => ({
    rules: {
      globalRules: [v => !!v || "This field is required"],
      emailRules: [
        v => !!v || "This field is required",
        v => /.+@.+/.test(v) || "Invalid format"
      ]
    },
    modal: {
      show: false,
      text: "",
      title: ""
    },
    loading: false,
    valid: true,
    newEmail: "",
    make: "",
    model: "",
    year: "",
    engine: "",
    drivingStyle: "",
    drivingStyles: [
      "Daily street use",
      "Daily street use & occasional track days",
      "Track use only"
    ],
    typeOfCompetition: ""
  }),
  props: {
    dialog: Boolean,
    part: String,
    manufacturer: String
  },
  methods: {
    handleErrorModal() {
      this.modal.show = false;
    },
    closeModal(val) {
      this.$emit("modal", val);
    },
    sendEnquiry() {
      if (this.$refs.quickContactForm.validate()) {
        this.loading = true;
        const {
          part,
          make,
          model,
          year,
          engine,
          drivingStyle,
          newEmail,
          typeOfCompetition,
          manufacturer
        } = this;
        const payload = {
          make,
          model,
          year,
          engine,
          drivingStyle,
          typeOfCompetition,
          part,
          manufacturer,
          email: this.user ? this.user.email : newEmail
        };
        quickContactForm(payload)
          .then(res => {
            if (res.status === 200) {
              this.modal.title = "Success";
              this.modal.text = "Contact form submitted";
              this.modal.show = true;
            }
          })
          .catch(err => {
            const error = (err.response && err.response.data) || err;
            this.modal.title = "Server error";
            this.modal.text = error.message || error.errmsg || error;
          })
          .finally(() => {
            this.loading = false;
            this.$refs.quickContactForm.reset();
            this.$emit("modal", false);
          });
      }
    }
  },
  computed: {
    ...mapState(["user"]),
    dialogInner: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.closeModal(val);
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
