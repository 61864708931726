<template>
  <div>
    <div class="header-wrapper-exception turbosmart-banner">
      <h2>Turbosmart</h2>
      <hr class="line" />
    </div>
    <b-container>
      <div class="grid">
        <div class="text">
          <p>
            Turbosmart ist einer der weltweit führenden Hersteller von
            Turbozubehör. Immer mehr Rennteams und Tuningfirmen auf der ganzen
            Welt verwenden und empfehlen die Produkte von Turbosmart.
          </p>
          <p>
            Ihre Erfolge auf der Rennstrecke und bei Straßenveranstaltungen sind
            ein guter Beweis für die Wirksamkeit der Konstruktions-,
            Herstellungs- und Testprinzipien von Turbosmart. Die Rückmeldungen
            von Rennfahrern und Tunern fließen in die ständige Weiterentwicklung
            der Turbosmart-Produkte ein und stärken die Philosophie der
            kontinuierlichen Verbesserung.
          </p>
        </div>
        <div class="image">
          <picture>
            <source
              type="image/webp"
              srcset="./../../assets/brands-logos/p10_turbosmart.webp"
            />
            <img
              src="./../../assets/brands-logos/p10_turbosmart.png"
              alt="Disc Brakes Australia"
            />
          </picture>
        </div>
      </div>
    </b-container>
    <page-header
      :text="$t('importantLinks')"
      font="black"
      back="white"
    ></page-header>
    <b-container class="my-container">
      <div class="link-items">
        <router-link to="/catalogue/turbosmart">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Online-Katalog
          </div>
        </router-link>
        <a
          href="https://drive.google.com/file/d/1BxqIuAw4uZaBOUtfXl9lBhE8216x3oqs/view"
          target="_blank"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Pdf-Katalog
          </div>
        </a>
        <a href="http://www.turbosmart.com/" target="_blank">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Webseite
          </div>
        </a>
        <a
          href="https://drive.google.com/drive/folders/1ZhmfhAH-Wx9Jx-pmHtnnLPIpqLALxoL1"
          target="_blank"
          v-if="isUserLogged"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Useful Files
            <ul class="link-items-list">
              <li>Price Lists / Guides / Catalogues</li>
              <li>Artwork (Logos / Posters / Banners / Brochures )</li>
              <li>Product images</li>
              <li>Videos</li>
              <li>Technical Support / Fitting Instructions</li>
            </ul>
          </div>
        </a>
        <a
          href="https://www.youtube.com/channel/UCiPdbaflth_3zXeQMDHDTEg"
          target="_blank"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Youtube Channel
          </div>
        </a>
      </div>
      <div class="youtube-wrapper">
        <div class="youtube">
          <iframe
            width="100%"
            height="360"
            src="https://www.youtube.com/embed/7xQO_R87jVc"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div>
          <p>
            Turbosmart Ford Fiesta ST – der Klang des Kompact Shortie Dual Port
            Blow Off-Ventils
          </p>
          <p>
            Hören Sie den herrlichen Klang des Turbosmart Kompact Shortie Dual
            Port Blow Off-Ventils (TS-0203-1066), das in den Turbosmart Ford
            Fiesta ST eingebaut wurde. Das Kompact Shortie mag klein sein, es
            hat aber einen mächtigen Klang!
          </p>
        </div>
      </div>
      <brands class="brands" />
    </b-container>
  </div>
</template>

<script>
import Brands from "@/components/brands/Brands.vue";
import PageHeader from "@/components/common/PageHeader.vue";
import { mapGetters } from "vuex";

export default {
  name: "TurbosmartEn",
  components: { Brands, PageHeader },
  computed: {
    ...mapGetters(["isUserLogged"])
  }
};
</script>
