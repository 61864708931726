<template>
  <div>
    <router-link
      v-if="object.item.urlcomponent"
      class="my-link"
      :to="`/${object.item.urlcomponent}`"
      target="_blank"
      >{{ object.displayname }}</router-link
    >
    <span v-else>{{ object.displayname }}</span>
  </div>
</template>

<script>
export default {
  name: "ItemName",
  props: {
    object: Object
  }
};
</script>

<style lang="scss" scoped>
.my-link:hover {
  text-decoration: none;
}
</style>
