<template>
  <v-btn
    :class="
      mobile ? 'mb-4 d-flex d-md-none btn' : 'plus-icon d-none d-md-flex btn'
    "
    small
    depressed
    @click="toggleComponents"
  >
    <v-icon v-if="showComponents" class="mr-2" small>mdi-minus</v-icon>
    <v-icon v-else class="mr-2" small>mdi-plus</v-icon>
    {{ showComponents ? "Hide" : "Show" }} components
  </v-btn>
</template>

<script>
export default {
  name: "ToggleComponentsBtn",
  props: {
    showComponents: Boolean,
    mobile: {
      type: Boolean,
      default: false
    }
  },
  setup(_, { emit }) {
    const toggleComponents = () => emit("toggleComponents");
    return {
      toggleComponents
    };
  }
};
</script>

<style lang="scss" scoped>
.plus-icon {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

@media screen and (max-width: 960px) {
  .btn {
    width: 100%;
  }
}
</style>
