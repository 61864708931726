const SOURCE =
  window.location.hostname === "ozperformance.uk" ||
  window.location.hostname === "ozperformance.uk"
    ? "ozperformanceuk"
    : "ozperformanceeu";

let CURRENCY = "eur";
let TAX = "5e0dbaba9e33df43f0b3a47f"; // 23%
let TAX_FLOAT = 1.23;
//let PRICELEVEL = "5e57c5e1845e9a3dcc93024d"; // 5%
//let PRICELEVEL_FLOAT = 1.05;
let URL_BASE = "https://ozperformance.eu";
let LOCATION_EU = "5e0dbaba9e33df43f0b3a495"; // EU
let LOCATION_UK = "5e0dbaba9e33df43f0b3a496"; // UK
//let BASEPRICE = "5e57c5e1845e9a3dcc93024d"; // 5%

export const PAYMENT_METHODS = {
  REVOLUT: "revolut",
  PAYPAL: "607468324e5b797a767fe87d"
};

if (SOURCE === "ozperformanceuk") {
  CURRENCY = "gbp";
  TAX = "5e22ee60f4e3f79cece0f938"; // 20%
  TAX_FLOAT = 1.2;
  //PRICELEVEL = "5e57c5e1845e9a3dcc93024d"; // 5%
  //PRICELEVEL_FLOAT = 1.05;
  URL_BASE = "https://ozperformance.co.uk";
  //BASEPRICE = "5e57c5e1845e9a3dcc93024d"; // 5%
}

export default {
  SOURCE,
  CURRENCY,
  TAX,
  TAX_FLOAT,
  //PRICELEVEL,
  //PRICELEVEL_FLOAT,
  URL_BASE,
  //BASEPRICE,
  LOCATION_EU,
  LOCATION_UK
};
