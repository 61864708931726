<template>
  <div class="px-10 mt-4 kit-components">
    <kit-component
      v-for="kit in kitComponents"
      :key="kit._id"
      :kit-component="kit"
    />
  </div>
</template>

<script>
import KitComponent from "./KitComponent.vue";

export default {
  name: "KitComponentList",
  components: { KitComponent },
  props: {
    kitComponents: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style lang="scss" scoped>
.kit-components {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (min-width: 960px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
  }
}
</style>
