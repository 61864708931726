import { render, staticRenderFns } from "./BrandCircle2.vue?vue&type=template&id=47ef9be7&"
import script from "./BrandCircle2.vue?vue&type=script&lang=js&"
export * from "./BrandCircle2.vue?vue&type=script&lang=js&"
import style0 from "./BrandCircle2.vue?vue&type=style&index=0&id=47ef9be7&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.1_css-loader@6.7.3_lodash@4.17.21_vue-template-compiler@2.7.14_webpack@5.75.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports