<template>
  <div>
    <div class="header-wrapper-exception acl-banner">
      <h2>ACL Bearings</h2>
      <hr class="line" />
    </div>
    <b-container>
      <div class="grid">
        <div class="text">
          <p>
            ACL ma bogatą historię w motorsporcie. Nie ma lepszego terenu
            doświadczalnego niż tor wyścigowy, bez względu na jego konfigurację.
            W trakcie konkurencji zawsze uwidacznie się sprawdzona technologia.
          </p>
          <p>
            Dzisiejsza gama panewek do silników wyczynowych z serii ACL RACE ma
            silne korzenie w motorsporcie i siega lat 60-tcy XX wieku dzięki ich
            produkcji do silników używanych przez Brabham Formula 1. Dalsze
            doświadczenie w Australian Supercars Championship i wielu innych
            zawodach poskutkowały stworzeniuem obecnej platformy technologicznej
            serii ACL RACE. Unikalne połączenie projektowania, metalurgii,
            inżynierii i precyzyjnej kontroli jakości pozwala uzyskać to, czego
            oczekują producenci silników od panewek perfomance.
          </p>
        </div>
        <div class="image">
          <picture>
            <source
              type="image/webp"
              srcset="./../../assets/brands-logos/p10_acl.webp"
            />
            <img
              src="./../../assets/brands-logos/p10_acl.png"
              alt="ACL Bearings"
            />
          </picture>
        </div>
      </div>
    </b-container>
    <page-header
      :text="$t('importantLinks')"
      font="black"
      back="white"
    ></page-header>
    <b-container>
      <div class="link-items">
        <router-link to="/catalogue/acl">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>
            {{ $t("catalogue") }} ON-LINE
          </div>
        </router-link>
        <a href="https://ozparts.eu/pdf/RSAG20.pdf" target="_blank">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>
            {{ $t("catalogue") }} PDF
          </div>
        </a>
        <a href="https://aclperformance.eu/" target="_blank">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>
            {{ $t("website") }}
          </div>
        </a>
        <a
          href="https://drive.google.com/open?id=1Jb_IprCzcQOPPbcK0U1iAHIY0e8THldk"
          target="_blank"
          v-if="isUserLogged"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>
            {{ $t("usefulFiles") }}
            <ul class="link-items-list">
              <li>{{ $t("usefulFiles1") }}</li>
              <li>{{ $t("usefulFiles2") }}</li>
              <li>{{ $t("usefulFiles3") }}</li>
              <li>{{ $t("usefulFiles4") }}</li>
              <li>{{ $t("usefulFiles5") }}</li>
            </ul>
          </div>
        </a>
      </div>
      <!-- <div class="youtube-wrapper">
        <div class="youtube">
          <a href="https://www.facebook.com/ACLRaceSeries/videos/464323550753461/" target="_blank">
            <picture>
              <source type="image/webp" srcset="./../../assets/acl_video.webp" />
              <img src="./../../assets/acl_video.jpg" />
            </picture>
          </a>
        </div>
        <div>
          <p>ACL Race Series</p>
          <p>We're proud to be a long-term partner of Kelly Racing. Check out Todd Kelly talking about the relationship they have with ACL!</p>
        </div>
      </div>-->
      <brands class="brands" />
    </b-container>
  </div>
</template>

<script>
import Brands from "@/components/brands/Brands.vue";
import PageHeader from "@/components/common/PageHeader.vue";
import { mapGetters } from "vuex";

export default {
  name: "AclPl",
  components: { Brands, PageHeader },
  computed: {
    ...mapGetters(["isUserLogged"])
  }
};
</script>
