<template>
  <div>
    <div class="header-wrapper-exception xforce-banner">
      <h2>Xforce</h2>
      <hr class="line" />
    </div>

    <b-container>
      <div class="grid">
        <div class="text">
          <p>
            XFORCE silnie koncentruje się na badaniach i rozwoju, które
            gwarantują, że ich produkty wydechowe charakteryzują się niezwykłą
            precyzją inżynierską, stylową i praktyczną konstrukcją
            zaprojektowaną tak, aby zmaksymalizować osiągi i co najważniejsze,
            technicznie innowacyjną. Dokładnie taką jak opatentowana przez nas
            aplikacja VAREX™, która sama w sobie jest rewolucją.
          </p>
          <p>
            Koncentracja na innowacyjności i kompleksowa kontrola jakości
            pozwala XFORCE zapewnić Państwu doskonałe osiągi waszych układów
            wydechowych. XFORCE jest mocno zaangażowany w nieustanne badania i
            rozwój, które łączą się z przewagą produkcyjną w produkcji układów
            wydechowych i inżynierii motoryzacyjnej. Systemy wydechowe zostały
            specjalnie zaprojektowane i przetestowane w centrum
            badawczo-rozwojowym Xforce w Australii, na najnowocześniejszej
            maszynie Dynapack Evolution 3000 z dynamometrem podwoziowym, w celu
            uzyskania maksymalnej wydajności.
          </p>
        </div>
        <div class="image">
          <picture>
            <source
              type="image/webp"
              srcset="./../../assets/brands-logos/p10_xforce.webp"
            />
            <img
              src="./../../assets/brands-logos/p10_xforce.png"
              alt="Xforce"
            />
          </picture>
        </div>
      </div>
    </b-container>

    <page-header
      :text="$t('importantLinks')"
      font="black"
      back="white"
    ></page-header>

    <b-container>
      <div class="link-items">
        <router-link to="/catalogue/xforce">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>
            {{ $t("catalogue") }} ON-LINE
          </div>
        </router-link>
        <!-- <a
          href="https://drive.google.com/open?id=1yZ0t1JXD-EuWlG5MNIYHvqKDDIYrOo4I"
          target="_blank"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>
            {{ $t("catalogue") }} PDF
          </div>
        </a> -->
        <a href="http://xforce.eu/" target="_blank">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>
            {{ $t("website") }}
          </div>
        </a>
        <a
          :href="
            $source === 'ozpartseu'
              ? 'https://drive.google.com/drive/folders/1ADhJnEp_xscrVlb3BH86ad3axo8B2NsX'
              : 'https://drive.google.com/open?id=1bPT7svkaHpD4pYJGf7aB3PE1jK9yysBs'
          "
          target="_blank"
          v-if="isUserLogged"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>
            {{ $t("usefulFiles") }}
            <ul class="link-items-list">
              <li>{{ $t("usefulFiles1") }}</li>
              <li>{{ $t("usefulFiles2") }}</li>
              <li>{{ $t("usefulFiles3") }}</li>
              <li>{{ $t("usefulFiles4") }}</li>
              <li>{{ $t("usefulFiles5") }}</li>
            </ul>
          </div>
        </a>
        <a href="https://www.youtube.com/user/xforceexhaust" target="_blank">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>
            {{ $t("youtube") }}
          </div>
        </a>
      </div>
      <!-- <div class="youtube-wrapper">
        <div class="youtube">
          <iframe
            width="100%"
            height="360"
            src="https://www.youtube.com/embed/xcfTcwAjNLU"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div>
          <p>XForce Engine-Back Exhaust System for Ford Mustang GT 5.0L 2015</p>
          <p>Here's our very own 2015 Mustang GT fitted with the new XForce 1-7/8" long tube header and 3" stainless catback with adjustable Varex rear mufflers. Have a listen!</p>
          <p>Now available from your local authorised XForce dealer: http://www.xforce.com.au/where-to-buy</p>
        </div>
      </div>-->
      <brands class="brands" />
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Brands from "@/components/brands/Brands.vue";
import PageHeader from "@/components/common/PageHeader.vue";

export default {
  name: "XforcePl",
  components: { Brands, PageHeader },
  computed: {
    ...mapGetters(["isUserLogged"])
  }
};
</script>
