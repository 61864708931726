<template>
  <div>
    <div class="side-table">
      <div class="table-item opposite">
        <div>{{ $t("ORDER.SUBTOTAL") }}</div>
        <div>
          {{ (order.itemsamount - airFreightCost).toFixed(2) }}
          {{ order.currency.symbol }}
        </div>
      </div>
      <div class="table-item opposite">
        <div>Airfreight Services</div>
        <div>
          {{ airFreightCost.toFixed(2) }}
          {{ order.currency.symbol }}
        </div>
      </div>
      <div class="table-item opposite">
        <div>{{ $t("ORDER.SHIPPING_TOTAL") }}</div>
        <div>
          {{ order.shippingcost.toFixed(2) }}
          {{ order.currency.symbol }}
        </div>
      </div>
      <div class="table-item opposite">
        <div>{{ $t("ORDER.TOTAL_TAX") }}</div>
        <div>{{ order.taxamount.toFixed(2) }} {{ order.currency.symbol }}</div>
      </div>
      <div class="table-item opposite">
        <div>{{ $t("ORDER.TOTAL") }}</div>
        <div>
          {{ order.grossamount.toFixed(2) }} {{ order.currency.symbol }}
        </div>
      </div>
      <div class="table-item download-button">
        <a
          :href="
            `https://3cerp.eu/api/pdf/transactions/${this.$route.params.id}`
          "
          target="_blank"
          >{{ $t("ORDER.DOWNLOAD_AS_PDF") }}</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderSideInfo",
  computed: {
    airFreightCost() {
      let cost = 0;
      this.order.transactionlines.forEach(line => {
        if (line.item.recordtype === "service") {
          cost += line.amount;
        }
      });
      return cost;
    }
  },
  props: {
    order: {
      type: Object,
      default: null
    }
  }
};
</script>
