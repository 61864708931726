<template>
  <router-link
    tag="div"
    :to="to"
    class="wrapper position-relative"
    active-class="mobile-active"
  >
    <v-icon :color="$route.path === to ? 'red' : null" class="icon" size="40">{{
      icon
    }}</v-icon>
    <div v-if="badge" class="shoppingcart-badge">
      {{ getBasketCount }}
    </div>
    <p>{{ text }}</p>
  </router-link>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "MobileIcon",
  props: {
    to: String,
    text: String,
    icon: String,
    badge: Boolean
  },
  computed: { ...mapGetters(["getBasketCount"]) }
};
</script>

<style lang="scss" scoped>
.shoppingcart-badge {
  position: absolute;
  top: 50%;
  left: 65%;
  color: white;
  background-color: #f44336;
  width: 20px;
  height: 20px;
  font-weight: 600;
  border-radius: 50%;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wrapper {
  display: flex;
  border-radius: 15%;
  text-align: center;
  font-family: "Montserrat";
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  background-color: lighten($color: lightgray, $amount: 14);
  font-size: 12px;

  .icon {
    margin: 10px 0;
  }

  p {
    margin-bottom: 5px;
  }
}
</style>

<style lang="scss" scoped>
.wrapper + .mobile-active {
  color: var(--red);
}
</style>
