<template>
  <article class="cookies">
    <page-header text="Cookies Policy" font="white" back="black" />
    <b-container>
      <p class="header">WHAT "COOKIES" ARE</p>
      <p>
        The oz4x4.eu portal uses so-called "cookies". Cookies are files
        containing various pieces of information that are saved on your device
        (computer, smartphone, etc.) when you visit websites. Our portal sends
        them to your browser, which then sends them back on the next visits to
        the site. Thanks to cookies, the oz4x4.eu portal recognises you and
        remembers, for example, your preferences (visits, clicks, previous
        actions).
      </p>
      <p>
        Cookies make it possible to adjust the operation of websites to the
        preferences of users. Thanks to cookies, we are able to refine and
        improve the Portal, so that its use is even easier and more convenient.
      </p>
      <p class="header">FOR WHAT PURPOSE DO WE USE "COOKIES"?</p>
      <p>Cookies are used by the oz4x4.eu Portal for the purpose of:</p>
      <ul>
        <li>
          adjusting the content of website to the preferences of the visitor and
          optimising the use of the website; in particular, these files allow
          the device of a visitor to the Portal to be recognised and proper
          display of the site, tailored to their individual needs;
        </li>
        <li>
          collecting statistics that inform about how visitors to the Portal use
          the websites, which allows improvements in their structure and content
          – the analysis of these statistics is anonymous and allows the content
          and appearance of the Portal to be adjusted to the prevailing trends,
          statistics are also used to evaluate the popularity of the website;
        </li>
        <li>
          maintaining the session of the person visiting the Portal before
          logging in, so that the Portal remembers the contents of the basket
          and the products added to it, and after logging in, it is not
          necessary to re-enter the login and password on each subpage
        </li>
        <li>
          ensuring the proper functioning of a chat provided by an external
          entity, i.e. Live Agent.
        </li>
      </ul>
      <p class="header">WHAT TYPES OF COOKIES DO WE USE?</p>
      <p>
        There are many types of cookies. The easiest way to describe them is on
        account of their characteristics.
      </p>
      <p>We can divide "cookies" according to:</p>
      <ol>
        <li>
          <strong>their necessity for use of our Portal:</strong>
          <ol>
            <li>
              <strong>Strictly necessary</strong> – as the name suggests, they
              are necessary for the proper functioning of the oz4x4.eu Portal
              and for the functionalities you want to use. Without these types
              of cookies, it would be impossible to make purchases in our online
              store. Some of them also ensure the security of the services we
              provide electronically.
            </li>
            <li>
              <strong>Preferences/Functionality</strong> – they are not
              necessary for the basic functioning of the Portal; however,
              disabling them will result in the website not being adapted to the
              individual preferences of users, and some functions will not work
            </li>
          </ol>
        </li>
        <li>
          <strong
            >the period for which the cookies will be placed on your
            device</strong
          >
          <ol>
            <li>
              <strong>Session cookies</strong> – remain in the device's web
              browser until it is turned off or exits the website on which they
              were placed;
            </li>
            <li>
              <strong>Persistent cookies</strong> – are not deleted after the
              browser is closed and remain in the device until they are deleted
              by the user or until the time defined in advance by the parameters
              in the "cookie" file
            </li>
          </ol>
        </li>
        <li>
          <strong>the entity that manages cookies</strong>
          <ol>
            <li>
              <strong>Own cookies</strong> – "cookies" placed on websites
              directly by the Portal Owner
            </li>
            <li>
              <strong>External cookies</strong> – "cookies" placed on websites
              by third parties, in connection with our use of tools such as
              Paypal to make payments and the use of information obtained in
              this way. In the event that the Portal calls up website components
              from outside the oz4x4.eu system, standards and rules of their
              "cookies" policy different to this "cookies" policy may apply. We
              encourage you to read the cookies policies of the entities whose
              tools we use:
              <ul>
                <li>
                  Google Llc. –
                  <a
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                    href="https://policies.google.com/technologies/cookies"
                    >https://policies.google.com/technologies/cookies</a
                  >
                </li>
                <li>
                  Vimeo Llc. –
                  <a
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                    href="https://vimeo.com/cookie_policy"
                    >https://vimeo.com/cookie_policy</a
                  >
                </li>
                <li>
                  Facebook Inc. –
                  <a
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                    href="https://www.facebook.com/policies/cookies"
                    >https://www.facebook.com/policies/cookies</a
                  >
                </li>
                <li>
                  Quality Unit Llc. (LiveAgent) –
                  <a
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                    href="https://www.liveagent.com/privacy-policy/"
                    >https://www.liveagent.com/privacy-policy/</a
                  >
                </li>
                <li>
                  PayPal Holdings Inc. –
                  <a
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                    href="https://www.paypal.com/myaccount/profile/flow/cookies/?locale=en_GB"
                    >https://www.paypal.com/myaccount/profile/flow/cookies/?locale=en_GB</a
                  >
                </li>
              </ul>
            </li>
          </ol>
        </li>
        <li>
          <strong>the purpose for which "cookies" are used</strong>
          <ol>
            <li>
              <strong>Website configuration</strong> – allow you to set up
              functions and services on websites.
            </li>
            <li>
              <strong>Security and reliability of websites</strong> – enable
              verification of authenticity and optimisation of website
              performance.
            </li>
            <li>
              <strong>Authentication</strong> – enable information regarding
              when the user is logged in, so that the website can show relevant
              information and functions.
            </li>
            <li>
              <strong>Session status</strong> – allow information to be recorded
              about how users use the website. They can relate to the most
              frequently visited pages or any error messages on some pages.
              Cookies used to save the so-called "Session state" help to improve
              services and enhance the browsing experience.
            </li>
            <li>
              <strong>Processes</strong> – enable efficient operation of the
              website and the functions available on it.
            </li>
            <li>
              <strong>Analyses and research, audience audit</strong> – enable
              the owner of websites to better understand the preferences of
              their users and, through analysis, to improve and develop products
              and services. Typically, the website owner or research company
              collects information anonymously and processes data on trends
              without identifying the personal data of individual users.
            </li>
            <li>
              <strong>Marketing and advertising</strong> – enable us to present
              personalised advertisements for our products using advertising
              networks that use cookies from third parties. Thanks to cookies,
              our ads are displayed on other websites to people who are
              interested in our products. They are activated when you visit our
              website and are only read by the respective advertising partner
              (e.g. Facebook or YouTube).
            </li>
          </ol>
        </li>
        <li>
          <strong>the scope of interference with the user's privacy</strong>
          <ol>
            <li>
              <strong>Harmless</strong> – these are cookies necessary for the
              proper functioning of the website and necessary to enable the
              functionality of the website.
            </li>
            <li>
              <strong>Analysing</strong> – used to track users, but do not
              include information that allows (by itself) identification of a
              specific user.
            </li>
          </ol>
        </li>
      </ol>
      <table class="cookie-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Supplier</th>
            <th>Purpose</th>
            <th>Expiry date</th>
            <th>Script type</th>
            <th>Usage type</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>__paypal_storage__</td>
            <td>paypal.com</td>
            <td>Allows payment</td>
            <td>Persistent</td>
            <td>HTML Local Storage</td>
            <td>Strictly necessary</td>
          </tr>
          <tr>
            <td>basketCount</td>
            <td>oz4x4.eu</td>
            <td>Stores data about the size of the basket</td>
            <td>1 Day</td>
            <td>HTTP Cookie</td>
            <td>Strictly necessary</td>
          </tr>
          <tr>
            <td>cart_id</td>
            <td>oz4x4.eu</td>
            <td>Stores the ID of the shopping cart</td>
            <td>1 Day</td>
            <td>HTTP Cookie</td>
            <td>Strictly necessary</td>
          </tr>
          <tr>
            <td>consent</td>
            <td>oz4x4.eu</td>
            <td>Stores the user's consent (if given)</td>
            <td>Persistent</td>
            <td>HTML Local Storage</td>
            <td>Strictly necessary</td>
          </tr>
          <tr>
            <td>SessionID</td>
            <td>paypal.com</td>
            <td>Allows payment</td>
            <td>3 years</td>
            <td>HTTP Cookie</td>
            <td>Strictly necessary</td>
          </tr>
          <tr>
            <td>enforce_policy</td>
            <td>paypal.com</td>
            <td>Allows payment</td>
            <td>1 year</td>
            <td>HTTP Cookie</td>
            <td>Strictly necessary</td>
          </tr>
          <tr>
            <td>l7_az</td>
            <td>paypal.com</td>
            <td>Allows payment</td>
            <td>1 day</td>
            <td>HTTP Cookie</td>
            <td>Strictly necessary</td>
          </tr>
          <tr>
            <td>ts_c</td>
            <td>paypal.com</td>
            <td>Allows payment</td>
            <td>3 years</td>
            <td>HTTP Cookie</td>
            <td>Strictly necessary</td>
          </tr>
          <tr>
            <td>ts</td>
            <td>paypal.com</td>
            <td>Allows payment</td>
            <td>Session</td>
            <td>Pixel Tracker</td>
            <td>Strictly necessary</td>
          </tr>
          <tr>
            <td>LaSID</td>
            <td>liveagent.com</td>
            <td>Enables direct communication via the chat</td>
            <td>Session</td>
            <td>HTTP Cookie</td>
            <td>Statistical</td>
          </tr>
          <tr>
            <td>_ga</td>
            <td>google.com</td>
            <td>
              Registers a unique ID used to monitor user activity on the website
            </td>
            <td>2 years</td>
            <td>HTTP Cookie</td>
            <td>Statistical</td>
          </tr>
          <tr>
            <td>_gat</td>
            <td>google.com</td>
            <td>Optimises the number of requests to the server</td>
            <td>1 Day</td>
            <td>HTTP Cookie</td>
            <td>Statistical</td>
          </tr>
          <tr>
            <td>_gid</td>
            <td>google.com</td>
            <td>
              Registers a unique ID used to monitor user activity on the website
            </td>
            <td>1 Day</td>
            <td>HTTP Cookie</td>
            <td>Statistical</td>
          </tr>
          <tr>
            <td>LaVisitorNew</td>
            <td>liveagent.com</td>
            <td>Enables direct communication via the chat</td>
            <td>1 Day</td>
            <td>HTTP Cookie</td>
            <td>Statistical</td>
          </tr>
          <tr>
            <td>vuid</td>
            <td>vimeo.com</td>
            <td>
              Stores user preferences in relation to video player settings
            </td>
            <td>2 years</td>
            <td>HTTP Cookie</td>
            <td>Statistical</td>
          </tr>
          <tr>
            <td>_fbp</td>
            <td>facebook.com</td>
            <td>Used by Facebook to pair advertising content</td>
            <td>3 months</td>
            <td>HTTP Cookie</td>
            <td>Marketing</td>
          </tr>
          <tr>
            <td>LaVisitorId_b3pwYXJ0cy5sYWRlc2suY29tLw</td>
            <td>liveagent.com</td>
            <td>Enables direct communication via the chat</td>
            <td>Session</td>
            <td>HTTP Cookie</td>
            <td>Marketing</td>
          </tr>
          <tr>
            <td>IDE</td>
            <td>google.com</td>
            <td>
              Used by Google to monitor interactions with items advertised via
              the browser
            </td>
            <td>1 year</td>
            <td>HTTP Cookie</td>
            <td>Marketing</td>
          </tr>
          <tr>
            <td>test_cookie</td>
            <td>google.com</td>
            <td>Checks whether the browser supports cookies</td>
            <td>1 Day</td>
            <td>HTTP Cookie</td>
            <td>Marketing</td>
          </tr>
          <tr>
            <td>fr</td>
            <td>facebook.com</td>
            <td>Used by Facebook to pair advertising content</td>
            <td>3 months</td>
            <td>HTTP Cookie</td>
            <td>Marketing</td>
          </tr>
          <tr>
            <td>tr</td>
            <td>facebook.com</td>
            <td>Used by Facebook to pair advertising content</td>
            <td>Session</td>
            <td>Pixel Tracker</td>
            <td>Marketing</td>
          </tr>
          <tr>
            <td>VISITOR_INFO1_LIVE</td>
            <td>youtube.com</td>
            <td>
              Stores user preferences in relation to video player settings
            </td>
            <td>179 days</td>
            <td>HTTP Cookie</td>
            <td>Marketing</td>
          </tr>
          <tr>
            <td>YSC</td>
            <td>youtube.com</td>
            <td>
              Stores user preferences in relation to video player settings
            </td>
            <td>Session</td>
            <td>HTTP Cookie</td>
            <td>Marketing</td>
          </tr>
          <tr>
            <td>yt.innertube::nextId</td>
            <td>youtube.com</td>
            <td>
              Stores user preferences in relation to video player settings
            </td>
            <td>Persistent</td>
            <td>HTML Local Storage</td>
            <td>Marketing</td>
          </tr>
          <tr>
            <td>yt.innertube::requests</td>
            <td>youtube.com</td>
            <td>
              Stores user preferences in relation to video player settings
            </td>
            <td>Persistent</td>
            <td>HTML Local Storage</td>
            <td>Marketing</td>
          </tr>
          <tr>
            <td>yt-remote-cast-installed</td>
            <td>youtube.com</td>
            <td>
              Stores user preferences in relation to video player settings
            </td>
            <td>Session</td>
            <td>HTML Local Storage</td>
            <td>Marketing</td>
          </tr>
          <tr>
            <td>yt-remote-connected-devices</td>
            <td>youtube.com</td>
            <td>
              Stores user preferences in relation to video player settings
            </td>
            <td>Persistent</td>
            <td>HTML Local Storage</td>
            <td>Marketing</td>
          </tr>
          <tr>
            <td>yt-remote-device-id</td>
            <td>youtube.com</td>
            <td>
              Stores user preferences in relation to video player settings
            </td>
            <td>Persistent</td>
            <td>HTML Local Storage</td>
            <td>Marketing</td>
          </tr>
          <tr>
            <td>yt-remote-fast-check-period</td>
            <td>youtube.com</td>
            <td>
              Stores user preferences in relation to video player settings
            </td>
            <td>Session</td>
            <td>HTML Local Storage</td>
            <td>Marketing</td>
          </tr>
          <tr>
            <td>yt-remote-session-app</td>
            <td>youtube.com</td>
            <td>
              Stores user preferences in relation to video player settings
            </td>
            <td>Session</td>
            <td>HTML Local Storage</td>
            <td>Marketing</td>
          </tr>
          <tr>
            <td>yt-remote-session-name</td>
            <td>youtube.com</td>
            <td>
              Stores user preferences in relation to video player settings
            </td>
            <td>Session</td>
            <td>HTML Local Storage</td>
            <td>Marketing</td>
          </tr>
        </tbody>
      </table>
      <p class="header">
        WHAT DATA DO "COOKIES" COLLECT? IS THIS PERSONAL DATA?
      </p>
      <ol>
        <li>
          The information collected relates in particular to the IP address,
          type of browser used, language, type of operating system, Internet
          service provider, information about time and date, location.
        </li>
        <li>
          As a rule, cookies do not constitute personal data. However, certain
          information stored in "cookies" (e.g. regarding preferences),
          especially when combined with other information about the website
          user, may be considered personal data. Personal data collected using
          "cookies" may be processed only for the purpose of performing specific
          functions for the user, described above. Such data is encrypted in a
          way that prevents access by unauthorised persons
        </li>
        <li>
          In order to exercise the right to object to the processing of such
          data, which is granted by the GDPR, it is necessary to log out of all
          devices and delete cookies from them.
        </li>
      </ol>
      <p class="header">HOW TO MANAGE COOKIES</p>
      <ol>
        <li>
          In many cases, the software used for browsing websites (web browser)
          allows cookies to be stored on the visitor’s end device by default. In
          this case, the user can change the cookie settings at any time. These
          settings can be changed in particular in such a way as to block the
          automatic handling of cookies in the web browser settings or to inform
          the user about each entry in the device of a visitor to the Portal.
          Detailed information on the possibilities and methods of handling
          cookies is available in the software (web browser) settings.
        </li>
        <li>
          Detailed information about how to manage cookies for some browsers:
          <ul>
            <li>
              Firefox:
              <a
                target="_blank"
                rel="noopener noreferrer nofollow"
                href="https://support.mozilla.org/pl/kb/ciasteczka"
                >https://support.mozilla.org/pl/kb/ciasteczka</a
              >
            </li>
            <li>
              Chorme:
              <a
                target="_blank"
                rel="noopener noreferrer nofollow"
                href="https://support.google.com/chrome/answer/95647?hl=pl"
                >https://support.google.com/chrome/answer/95647?hl=pl</a
              >
            </li>
            <li>
              Edge:
              <a
                target="_blank"
                rel="noopener noreferrer nofollow"
                href="https://support.microsoft.com/pl-pl/topic/usuwanie-plik%C3%B3w-cookie-i-zarz%C4%85dzanie-nimi-168dab11-0753-043d-7c16-ede5947fc64d"
                >https://support.microsoft.com/pl-pl/topic/usuwanie-plik%C3%B3w-cookie-i-zarz%C4%85dzanie-nimi-168dab11-0753-043d-7c16-ede5947fc64d</a
              >
            </li>
            <li>
              Safari:
              <a
                target="_blank"
                rel="noopener noreferrer nofollow"
                href="https://support.apple.com/kb/ph21411?locale=pl_PL"
                >https://support.apple.com/kb/ph21411?locale=pl_PL</a
              >
            </li>
            <li>
              Opera:
              <a
                target="_blank"
                rel="noopener noreferrer nofollow"
                href="https://help.opera.com/Windows/12.10/pl/cookies.html"
                >https://help.opera.com/Windows/12.10/pl/cookies.html</a
              >
            </li>
          </ul>
        </li>
        <li style="text-decoration: underline;">
          The administrator informs that restrictions on the use of cookies may
          affect some of the functionalities available on the Portal's websites.
        </li>
        <li>
          If you want to know more about what cookies are and how they work,
          please visit the site
          <a href="https://www.allaboutcookies.org/"
            >https://www.allaboutcookies.org/</a
          >
        </li>
      </ol>
    </b-container>
  </article>
</template>

<script>
import PageHeader from "@/components/common/PageHeader";
export default {
  name: "CookiesEn",
  components: { PageHeader }
};
</script>

<style scoped>
.cookies {
  font-weight: 300;
}

.header {
  line-height: 1.5;
  word-spacing: 5px;
  margin: 20px 0;
  font-family: Michroma, sans-serif;
  font-size: medium;
}
</style>
