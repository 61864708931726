<template>
  <div class="photo">
    <picture>
      <source
        v-if="object.item.photos[0]"
        :srcset="object.item.photos[0].url"
        :alt="object.displayname"
      />
      <source
        type="image/webp"
        srcset="../../assets/photo-holder.webp"
        :alt="object.displayname"
      />
      <img src="../../assets/photo-holder.png" :alt="object.displayname" />
    </picture>
    <div v-if="isAir" class="air-icon">
      <v-icon color="red"> mdi-airplane </v-icon>
    </div>
  </div>
</template>

<script>
export default {
  name: "ItemPhoto",
  props: {
    object: Object
  },
  setup(props) {
    const isAir = !!props.object.additionalservice.find(service =>
      service.name.includes("A/F")
    );

    return { isAir };
  }
};
</script>

<style lang="scss" scoped>
.photo {
  position: relative;
  background-color: white;

  .air-icon {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  img {
    height: 150px;
    width: 100%;
    object-fit: contain;
    border: 1px solid #ced4da;
  }
}
</style>
