<template>
  <div class="wrapper">
    <hr class="line" />
    <p class="text">{{ $t("quickContactHeader") }}</p>
    <b-button class="button" @click="showForm = true">{{
      $t("CATALOGUE.QUICK_CONTACT_FORM")
    }}</b-button>
    <quick-contact-form
      :manufacturer="manufacturer"
      :part="part"
      :dialog="showForm"
      @modal="handleModal"
    />
  </div>
</template>

<script>
import QuickContactForm from "./QuickContactForm.vue";

export default {
  name: "QuickContact",
  data: () => ({
    showForm: false
  }),
  methods: {
    handleModal(val) {
      this.showForm = val;
    }
  },
  props: {
    part: String,
    manufacturer: String
  },
  components: {
    QuickContactForm
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  align-items: center;

  .line {
    border-top: 2px solid black;
    display: block;
    height: 1px;
    border: 0;
    border-top: 2px solid black;
    margin: 1em auto;
    padding: 0;
    margin-top: 1em;
    width: 7em;
  }

  .text {
    text-align: center;
    font-size: 16px;
    line-height: 1.5;
    word-spacing: 5px;
    font-weight: bold;
  }

  .button {
    margin-top: 20px;
    background-color: var(--red);
    text-transform: uppercase;
    border: none;
    width: 300px;

    &:hover {
      cursor: pointer;
      background-color: darken(#ed3730, 10%);
    }
  }
}
</style>
