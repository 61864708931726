<template>
  <b-container>
    <div class="brand-items">
      <brand-circle2
        v-for="brand in brands"
        :key="brand.header"
        :brand="brand"
        class="brand-item"
        @catalogueSelected="$router.push(brand.to)"
      />
    </div>
  </b-container>
</template>

<script>
import BrandCircle2 from "../brands/BrandCircle2";

const dba = require("./../../assets/kolecka/Kolecka_DBA.png");
const xp = require("./../../assets/kolecka/Kolecka_XP.png");
const hawk = require("./../../assets/kolecka/o-hawk.jpg");
const xforce = require("./../../assets/kolecka/xforce1_lighter2.png");
const pedders = require("./../../assets/kolecka/o-pedders.jpg");
const turbosmart = require("./../../assets/kolecka/o-turbosmart.jpg");
const whiteline = require("./../../assets/kolecka/o-whiteline.jpg");
const acl = require("./../../assets/kolecka/o-acl.png");

const dbaWebp = require("./../../assets/kolecka/Kolecka_DBA.webp");
const xpWebp = require("./../../assets/kolecka/Kolecka_XP.webp");
const hawkWebp = require("./../../assets/kolecka/o-hawk.webp");
const xforceWebp = require("./../../assets/kolecka/xforce1_lighter2.webp");
const peddersWebp = require("./../../assets/kolecka/o-pedders.webp");
const turbosmartWebp = require("./../../assets/kolecka/o-turbosmart.webp");
const whitelineWebp = require("./../../assets/kolecka/o-whiteline.webp");
const aclWebp = require("./../../assets/kolecka/o-acl.webp");

export default {
  name: "AboutCircles",
  components: { BrandCircle2 },
  data: () => ({
    brands: [
      {
        translationKey: "DBA",
        header: "DBA",
        dsc: "Disc Brakes & Pads",
        img: dba,
        imgWebp: dbaWebp,
        to: "/catalogue/dba"
      },
      {
        translationKey: "ACS",
        header: "XTREME CLUTCH",
        dsc: "High Performance Clutch & Components",
        img: xp,
        imgWebp: xpWebp,
        to: "/catalogue/xclutch"
      },
      {
        translationKey: "HAWK",
        header: "HAWK",
        dsc: "Brake Pads",
        img: hawk,
        imgWebp: hawkWebp,
        to: "/catalogue/hawk"
      },
      {
        translationKey: "XFORCE",
        header: "XFORCE",
        dsc: "Performance Exhaust",
        img: xforce,
        imgWebp: xforceWebp,
        to: "/catalogue/xforce"
      },
      {
        translationKey: "PEDDERS",
        header: "PEDDERS",
        dsc: "Suspension, Shock Absorbers and Springs",
        img: pedders,
        imgWebp: peddersWebp,
        to: "/catalogue/pedders",
        hideOn: ["ozpartsuk"]
      },
      {
        translationKey: "TURBOSMART",
        header: "TURBOSMART",
        dsc: "Turbo Performance Products",
        img: turbosmart,
        imgWebp: turbosmartWebp,
        to: "/catalogue/turbosmart",
        hideOn: ["ozpartsuk"]
      },
      {
        translationKey: "WHITELINE",
        header: "WHITELINE",
        dsc: "Stability Control Components",
        img: whiteline,
        imgWebp: whitelineWebp,
        to: "/catalogue/whiteline",
        hideOn: ["ozpartsuk"]
      },
      {
        translationKey: "ACL",
        header: "ACL RACE SERIES",
        dsc: "Performance Engine Bearings",
        img: acl,
        imgWebp: aclWebp,
        to: "/catalogue/acl",
        hideOn: ["ozpartsuk"]
      }
    ]
  })
};
</script>

<style scoped lang="scss">
h2 {
  text-align: center;
  text-transform: uppercase;
  word-spacing: 5px;
}

.line {
  display: block;
  height: 1px;
  border: 0;
  border-top: 2px solid black;
  margin: 1em auto;
  padding: 0;
  width: 10em;
}

.brand-items {
  @media screen and (min-width: 600px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (min-width: 960px) {
    grid-template-columns: repeat(4, 1fr);
  }

  .brand-item {
    margin-top: 2em;
    display: flex;
    justify-content: center;

    @media screen and (min-width: 600px) {
      margin: 1em;
    }
  }
}
</style>
