<template>
  <div class="circle-wrapper">
    <div class="ih-item circle effect3 left_to_right">
      <router-link :to="brand.to">
        <div class="img">
          <picture>
            <source type="image/webp" :srcset="brand.imgWebp" />
            <img :src="brand.img" alt="img" />
          </picture>
        </div>
        <div class="my-info">
          <h3 class="circle-text">{{ brand.header }}</h3>
          <p>{{ $t(`BRAND_CIRCLE.${brand.translationKey}`) }}</p>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "BrandCircle",
  props: {
    brand: Object
  }
};
</script>

<style lang="scss">
@import "./../../styles/ihover.min.css";

.circle-wrapper {
  .ih-item.circle {
    position: relative;
    border-radius: 50%;
  }
  .ih-item.circle .img {
    position: relative;
    width: 180px !important;
    height: 180px !important;
    border-radius: 50%;
    top: 0;
  }

  .ih-item.circle.effect3 .my-info h3 {
    padding: 60px 0 0 0 !important;
  }

  .ih-item.circle.effect3 .my-info p {
    margin-top: 15px !important;
  }

  .ih-item.circle {
    width: 180px !important;
    height: 180px !important;
  }

  .ih-item.circle.effect3.left_to_right a:hover .img {
    top: -65px;
    -webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
  }
  .ih-item.circle.effect3 .my-info h3 {
    font-size: 12px !important;
    font-family: "Michroma", sans-serif;
    height: 50px !important;
    padding-bottom: 2em !important;
  }
}
</style>
