<template>
  <div class="wrapper">
    <div class="buttons">
      <div
        class="nav-button"
        :class="{ 'nav-active': active == 'overview' }"
        @click="handleClick('overview')"
      >
        {{ $t("overview") }}
      </div>
      <div
        class="nav-button"
        :class="{ 'nav-active': active == 'techDetails' }"
        @click="handleClick('techDetails')"
      >
        {{ $t("techDetails") }}
      </div>
      <div
        class="nav-button"
        :class="{ 'nav-active': active == 'applications' }"
        @click="handleClick('applications')"
      >
        {{ $t("applications") }}
      </div>
      <div
        v-if="relatedFiltered.length"
        class="nav-button"
        :class="{ 'nav-active': active == 'related' }"
        @click="handleClick('related')"
      >
        {{ $t("related") }}
      </div>
    </div>
    <div class="content">
      <div class="overview" v-show="active === 'overview'">
        <div v-html="object.detaildescription"></div>
        <kit-components
          v-if="
            object.components &&
              object.components.length &&
              object.manufacturergroup !== '5f9bda55dcdf6b0c04f1878d' &&
              object.manufacturergroup !== redarc
          "
          :components="object.components"
          :base="object.name"
        />
        <quick-contact
          :part="object.name"
          :manufacturer="object.manufacturer"
        />
      </div>
      <div v-show="active === 'applications'">
        <applications-component
          :acl="acl"
          :object="object"
          :applications="applications"
        />
        <quick-contact
          :part="object.name"
          :manufacturer="object.manufacturer"
        />
      </div>
      <div class="related-items" v-show="active === 'related'">
        <div class="loader" v-if="showRelatedSpinner">
          <v-progress-circular indeterminate></v-progress-circular>
        </div>
        <item-card
          v-else
          v-for="item in relatedFiltered"
          :data="item"
          :key="item.name"
        />
      </div>
      <div class="tech-info" v-show="active === 'techDetails'">
        <tech-info
          :object="object"
          :axle="axle"
          :comboComponents="comboComponents"
        />
        <quick-contact
          :part="object.name"
          :manufacturer="object.manufacturer"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ItemCard from "@/components/common/ItemCard.vue";
import TechInfo from "./tabs/TechInfo.vue";
import KitComponents from "./tabs/KitComponents.vue";
import ApplicationsComponent from "./tabs/Applications.vue";
import QuickContact from "./quickContact/QuickContact.vue";
import { MANUFACTURER } from "../../vars";

export default {
  name: "TabsDetails",
  components: {
    ItemCard,
    TechInfo,
    ApplicationsComponent,
    QuickContact,
    KitComponents
  },
  computed: {
    redarc: () => {
      return MANUFACTURER.REDARC;
    },
    relatedFiltered() {
      const set = new Set(this.object.relateditems.map(item => item.id));
      const ids = Array.from(set);
      const arr = ids.map(id =>
        this.object.relateditems.find(item => item.id === id)
      );
      return arr.filter(
        item =>
          (!item.price || item.price.baseprice !== "0.00") &&
          item.name !== this.object.name
      );
    }
  },
  props: {
    combo: Boolean,
    comboComponents: Array,
    showRelatedSpinner: Boolean,
    active: String,
    object: Object,
    applications: Array,
    acl: [Object, Array],
    axle: String
  },
  methods: {
    handleClick(clickedTab) {
      this.$emit("clickedTab", clickedTab);
    }
  }
};
</script>

<style lang="scss" scoped>
.content {
  padding: 20px 0;
}

.loader {
  display: flex;
  justify-content: center;
  width: 100%;
}

.nav-button {
  word-spacing: 5px;
  @media screen and (max-width: 960px) {
    display: block;
  }
}

.table-details {
  padding: 0;
}

.related-items {
  display: flex;
  flex-wrap: wrap;
}

div:deep(.overview) {
  max-width: 100vw;
  overflow: hidden;

  p {
    margin: 0 0 20px;
    max-width: 1000px;
    font-size: 16px;
    line-height: 1.5;
  }

  h3 {
    font-size: 1.5rem !important;
    font-weight: 600 !important;
  }

  p > img {
    width: auto;
    max-width: 100%;
    height: 100%;
    margin-bottom: 20px;
  }

  tbody > tr > td > img.icon {
    width: 30px !important;
    height: 30px !important;
  }

  table > tbody {
    display: flex;
    flex-direction: column;
  }

  table > tbody > tr:nth-child(1) > td {
    width: 32% !important;
  }

  table > tbody > tr {
    display: flex;
    justify-content: space-between;
  }

  table > tbody > tr:nth-child(2) > td:nth-child(1),
  table > tbody > tr:nth-child(5) > td:nth-child(1),
  table > tbody > tr:nth-child(7) > td:nth-child(1),
  table > tbody > tr:nth-child(8) > td:nth-child(1),
  table > tbody > tr:nth-child(9) > td:nth-child(1),
  table > tbody > tr:nth-child(11) > td:nth-child(1) {
    display: flex;
    flex-direction: column;
    align-items: start;
  }
  table > tbody > tr > td {
    padding: 4px;
    font-size: 11px;
    flex-basis: 32% !important;
  }

  table > tbody > tr:nth-child(3),
  tr:nth-child(4),
  tr:nth-child(6),
  tr:nth-child(10),
  tr:nth-child(12) {
    justify-content: flex-end;
    margin: 1px;
    gap: 1.3%;
  }
  table > tbody > tr:nth-child(3) > td,
  tr:nth-child(4) > td,
  tr:nth-child(6) > td,
  tr:nth-child(10) > td,
  tr:nth-child(12) > td {
    justify-content: flex-end;
    margin: 1px;
    width: 32% !important;
  }

  @media (width >= 640px) {
    table > tbody > tr > td > img.icon {
      width: 50px !important;
      height: 50px !important;
      margin-bottom: 5px !important;
    }

    table > tbody > tr > td {
      font-size: 16px;
    }

    table > tbody > tr:nth-child(1) > td {
      font-size: 16px;
      padding: 12px 0;
      font-weight: 500;
      text-transform: uppercase;
    }
  }
}
</style>
