<template>
  <div>
    <div class="header-wrapper-exception acl-banner">
      <h2>ACL Bearings</h2>
      <hr class="line" />
    </div>
    <b-container>
      <div class="grid">
        <div class="text">
          <p>
            ACL kann auf eine eindrucksvolle und reiche Geschichte im Motorsport
            zurückblicken. Es gibt kein besseres Testgelände als die
            Rennstrecke, egal wie sie beschaffen ist. In der Hitze des
            Wettbewerbs setzt sich bewährte Technologie immer durch.
          </p>
          <p>
            Die heutige ACL RACE Serie von Hochleistungs-Motorenlagern hat einen
            starken Stammbaum im Motorsport und kann ihre Wurzeln bis in die
            1960er Jahre zurückverfolgen, als sie Motorlager für den Einsatz in
            der weltbesten Brabham Formel 1 herstellte. Durch weitere
            Erfahrungen in der australischen Supercars-Meisterschaft und
            zahlreichen anderen Rennserien ist die aktuelle Technologieplattform
            der ACL RACE-Serie entstanden. Die einzigartige Kombination aus
            Design, Metallurgie, Technik und präziser Qualitätskontrolle liefert
            genau das, was Motorenbauer von Hochleistungsmotorenlagern erwarten.
          </p>
        </div>
        <div class="image">
          <picture>
            <source
              type="image/webp"
              srcset="./../../assets/brands-logos/p10_acl.webp"
            />
            <img
              src="./../../assets/brands-logos/p10_acl.png"
              alt="ACL Bearings"
            />
          </picture>
        </div>
      </div>
    </b-container>
    <page-header
      :text="$t('importantLinks')"
      font="black"
      back="white"
    ></page-header>
    <b-container>
      <div class="link-items">
        <router-link to="/catalogue/acl">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Online-Katalog
          </div>
        </router-link>
        <a href="https://ozparts.eu/pdf/RSAG20.pdf" target="_blank">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Pdf-Katalog
          </div>
        </a>
        <a href="https://aclperformance.eu/" target="_blank">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Webseite
          </div>
        </a>
        <a
          href="https://drive.google.com/open?id=1Jb_IprCzcQOPPbcK0U1iAHIY0e8THldk"
          target="_blank"
          v-if="isUserLogged"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>Useful Files
            <ul class="link-items-list">
              <li>Price Lists / Guides / Catalogues</li>
              <li>Artwork (Logos / Posters / Banners / Brochures )</li>
              <li>Product images</li>
              <li>Videos</li>
              <li>Technical Support / Fitting Instructions</li>
            </ul>
          </div>
        </a>
      </div>
      <div class="youtube-wrapper">
        <div class="youtube">
          <a
            href="https://www.facebook.com/ACLRaceSeries/videos/464323550753461/"
            target="_blank"
          >
            <picture>
              <source
                type="image/webp"
                srcset="./../../assets/acl_video.webp"
              />
              <img src="./../../assets/acl_video.jpg" alt="ACL video" />
            </picture>
          </a>
        </div>
        <div>
          <p>ACL Race Series</p>
          <p>
            Wir sind stolz darauf, ein langfristiger Partner von Kelly Racing zu
            sein. Sehen Sie sich Todd Kelly an, wie er über die Zusammenarbeit
            mit ACL spricht!
          </p>
        </div>
      </div>
      <brands class="brands" />
    </b-container>
  </div>
</template>

<script>
import Brands from "@/components/brands/Brands.vue";
import PageHeader from "@/components/common/PageHeader.vue";
import { mapGetters } from "vuex";

export default {
  name: "AclEn",
  components: { Brands, PageHeader },
  computed: {
    ...mapGetters(["isUserLogged"])
  }
};
</script>
