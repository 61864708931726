<template>
  <div
    class="circle2"
    :style="{ backgroundImage: `url('${brand.img}')` }"
    @click="selectCatalogue"
  ></div>
</template>

<script>
export default {
  name: "BrandCircle2",
  props: {
    brand: Object
  },
  methods: {
    selectCatalogue() {
      this.$emit("catalogueSelected", this.brand.header);
    }
  }
};
</script>

<style lang="scss">
.circle2 {
  display: inline-block;
  box-shadow: inset 0 0 0 0.5em rgba(255, 255, 255, 0.4);
  width: 180px;
  height: 180px;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  transition: box-shadow 0.5s;

  &:hover {
    box-shadow: inset 0 0 0 1em rgba(255, 255, 255, 0.4);
    transition: box-shadow 0.5s;
    cursor: pointer;
  }
}
</style>
