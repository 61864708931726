<template>
  <div class="hawk-wrapper">
    <hawk-en v-if="i18n.locale === 'en'" />
    <hawk-pl v-if="i18n.locale === 'pl'" />
    <hawk-de v-if="i18n.locale === 'de'" />
  </div>
</template>

<script>
import HawkEn from "@/components/brands/HawkEn.vue";
import HawkPl from "@/components/brands/HawkPl.vue";
import HawkDe from "@/components/brands/HawkDe.vue";
import i18n from "./../../i18n";

export default {
  name: "BrandHawk",
  components: { HawkEn, HawkPl, HawkDe },
  data: () => ({
    i18n
  })
};
</script>

<style lang="scss">
.hawk-wrapper {
  font-family: "Montserrat", sans-serif;

  .brands {
    margin-bottom: 30px;
  }

  .header-wrapper-exception {
    font-family: "Michroma", sans-serif;
    background-position: center;
    background-size: cover;
    text-align: center;
    margin-top: 2em;
    box-shadow: 0px 2px 10px 0px rgba(47, 47, 47, 0.3);
    color: white;
    padding: 1em;
    margin-bottom: 2em;

    h2 {
      text-shadow: 0 0 10px #2f2f2f;
      padding-top: 10px;
      text-transform: uppercase;
      font-size: 16px;
      line-height: 1.5;
      word-spacing: 5px;
    }

    .line {
      display: block;
      height: 1px;
      border: 0;
      border-top: 2px solid white;
      margin: 1em auto;
      padding: 0;
      margin-top: 1em;
      width: 7em;
    }
  }

  .text {
    line-height: 1.5em;
    padding: 0 1em;
    font-weight: 200;
    font-size: medium;

    p:first-of-type {
      font-weight: bold;
    }
  }

  .image {
    padding: 0 2em;
    margin: 2em 0;
    img {
      width: 100%;
      object-fit: contain;
    }
  }

  .links {
    text-transform: uppercase;
    margin: 2em auto;
    padding: 1em;
    text-align: center;
    margin-top: 2em;

    .line {
      display: block;
      height: 1px;
      border: 0;
      border-top: 2px solid black;
      margin: 1em auto;
      padding: 0;
      margin-top: 1em;
      width: 10em;
    }
  }

  .link-items {
    margin-bottom: 2em;

    a {
      text-decoration: none;
      &:last-of-type {
        .link-item {
          border-bottom-width: 1px;
        }
      }
    }

    .link-item {
      border: 1px solid lightgrey;
      border-bottom-width: 0;
      color: black;
      padding: 1em;

      &:hover .icon {
        color: var(--red);
      }

      .link-items-list {
        margin-top: 0.5em;
        margin-left: 2em;

        li {
          line-height: 1.8em;
        }
      }
    }
  }

  .youtube-wrapper {
    div {
      padding: 1em;
      line-height: 1.5em;
      font-size: medium;
      font-weight: 200;

      p:first-of-type {
        font-family: "Michroma", sans-serif;
      }
    }

    @media screen and (min-width: 960px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media screen and (min-width: 960px) {
    .grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
</style>

<style lang="scss">
.no-webp .hawk-banner {
  background-image: url("./../../assets/brands-banners/bcg-Hawk.png");
}

.webp .hawk-banner {
  background-image: url("./../../assets/brands-banners/bcg-Hawk.webp");
}
</style>
